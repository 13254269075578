import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Route, Redirect, Switch } from 'react-router-dom'

import BasePage from './BasePage'
import Dashboard from 'pages/Dashboard'
import JobPage from './pages/Job'
import Login from './pages/Login'
import TeamPage from 'pages/Team'
import { EditTeam } from 'pages/Team/tabs/Team/Edit/'
import { TeamInfo } from 'pages/Team/tabs/Team/TeamInfo'
import { JobInfo } from 'pages/Job/JobInfo'
import { ReportInfo } from 'pages/Report/ReportInfo'
import ReportEditPage from 'pages/Report/ReportEditPage'
import ReportPage from 'pages/Report'
import Reset from './pages/Reset'
import { ErrorPage } from 'pages/Error'
import { Layout, Loading } from 'theme'
import Swal, { SweetAlertOptions } from 'sweetalert2'

import { authenticate } from 'pages/Login/redux/authAction'
import { selectAlert, close as closeAlert } from 'redux/alert/alertSlice'

export const Routes = () => {
  const history = useHistory()
  const alert = useSelector(selectAlert)
  const dispatch = useDispatch()
  const { loading, isLoggedIn } = useSelector((state: any) => state.auth)

  useEffect(() => {
    dispatch(authenticate())
  }, [])

  useEffect(() => {
    handleShowAlert()
  }, [alert, dispatch, history])

  const alertOption = (): SweetAlertOptions => {
    switch (alert.type) {
      case 'error':
        return {
          icon: 'error',
          title: alert.message,
          // text: alert.message,
          confirmButtonText: 'OK',
          confirmButtonColor: '#f44336',
          showCancelButton: false,
          showConfirmButton: true,
          // focusConfirm: true,
        }
      default:
        return {
          html:
            '<span style="display: inline-flex;align-items:center;padding-top:0.75em;">' +
            '<img style="border-radius: 50%;margin-right:15px;" src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Icon-awesome-check-circle.png" width="30" height="30">' +
            `<p style="color: #1B4171;font-family:Roboto, Helvetica, Arial, sans-serif;">${alert.message}</p>` +
            '</span>',
          showConfirmButton: false,
          timer: 1500,
        }
    }
  }

  const handleShowAlert = () => {
    if (alert.isShow) {
      Swal.fire(alertOption()).then(() => {
        dispatch(closeAlert())

        if (alert.redirect) {
          window.location.href = alert.redirect
        }
      })
    }
  }

  return (
    <Switch>
      {loading && <Loading />}
      <Route exact path="/reset">
        <Reset />
      </Route>
      {isLoggedIn ? (
        <Redirect from="/login" to="/" />
      ) : (
        <Route>
          <Login />
        </Route>
      )}
      <Route path="/error" component={ErrorPage} />
      {isLoggedIn && (
        <Layout>
          <BasePage>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/teams/:tab" component={TeamPage} />
            <Route exact path="/teams/teams/:teamId" component={TeamInfo} />
            <Route path="/teams/teams/:teamId/edit" component={EditTeam} />
            <Route exact path="/jobs" component={JobPage} />
            <Route exact path="/jobs/:jobId" component={JobInfo} />
            <Route exact path="/reports" component={ReportPage} />
            <Route exact path="/reports/:reportId" component={ReportInfo} />
            <Route
              exact
              path="/reports/:reportId/edit"
              component={ReportEditPage}
            />
          </BasePage>
        </Layout>
      )}
      <Redirect to="/error" />
    </Switch>
  )
}
