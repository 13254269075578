import { IonSearchbar } from '@ionic/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './Search.scss'

interface IProps {
  text: string
  onSearch: any
}

const Search = ({ text, onSearch }: IProps) => {
  const { t } = useTranslation('placeholder')

  return (
    <IonSearchbar
      class="search-field"
      placeholder={t('searchbar-name-and-phonenumber-placeholder')}
      value={text}
      onIonChange={(e: any) => onSearch(e.target.value)}
      debounce={500}
    />
  )
}

Search.propTypes = {
  text: PropTypes.string,
  onSearch: PropTypes.func,
}

export default Search
