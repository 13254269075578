import { BaseResource } from "./BaseResource";

export class WFResource extends BaseResource {
  constructor() {
    const accessToken: any = localStorage.getItem("WFAgentAccessToken");
    const BASE_API = `${process.env.REACT_APP_WF_BASE_API}`;

    super(accessToken, BASE_API);
  }
}
