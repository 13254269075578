import React from 'react'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonAvatar,
  IonText,
} from '@ionic/react'
import { DateFormatter } from '../formatters'
import './CommentCard.scss'
import { useTranslation } from 'react-i18next'

const CommentCard = ({ value }) => {
  const { t } = useTranslation('common')

  const trimString = (string) => {
    return string?.length > 20 ? string.substring(0, 20) + '...' : string
  }

  return (
    <IonGrid className="comment-card-contanier">
      <IonRow>
        <IonCol size="2">
          <IonRow className="row-flex-end">
            <IonAvatar>
              {value?.agent !== undefined && value?.agent === null ? (
                <IonImg
                  className="service-icon"
                  src={value?.provider?.avatar_url}
                  alt="Service Icon"
                />
              ) : (
                <IonImg
                  className="service-icon"
                  src={value?.agent?.avatar_url}
                  alt="Service Icon"
                />
              )}
            </IonAvatar>
          </IonRow>
        </IonCol>

        <IonCol size="10">
          <IonRow className="row-flex-end">
            {value?.agent !== undefined && value?.agent === null ? (
              <>
                <IonText className="name-title">
                  {trimString(value?.provider?.display_name)}
                </IonText>
                <IonText className="position-title">{t('provider')}</IonText>
              </>
            ) : (
              <>
                <IonText className="name-title">
                  {trimString(value?.agent?.display_name)}
                </IonText>
                <IonText className="position-title">{t('admin')}</IonText>
              </>
            )}
          </IonRow>

          <IonRow>
            <IonText className="time-comment">
              <DateFormatter />
            </IonText>
          </IonRow>

          <IonRow>
            <IonText
              className="ion-text-wrap"
              style={{ wordBreak: 'break-word' }}
            >
              {value?.content}
            </IonText>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default CommentCard
