import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { NewTeam, TeamPage, ProviderList } from './tabs'

import './TeamPage.scss'

interface params {
  tab: string
}

const Teams = () => {
  const { tab: currentTab } = useParams<params>()
  const { t } = useTranslation('team')

  return (
    <>
      <IonGrid class="teams-header-content">
        <IonRow>
          <IonCol>
            <IonLabel class="team-page-label">
              <IonText>{t('team-and-providers')}</IonText>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText>{t('team-and-providers-description')}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid class="teams-page-content">
        <IonRow>
          <IonCol>
            <IonSegment class="tab-teams-segment" value={currentTab}>
              <Link to="/teams/new" className="tab-item">
                <IonSegmentButton value="new" className="create-team-button">
                  <IonText>{t('create-team')}</IonText>
                </IonSegmentButton>
              </Link>
              <Link to="/teams/teams" className="tab-item">
                <IonSegmentButton value="teams" className="team-button">
                  <IonText>{t('teams')}</IonText>
                </IonSegmentButton>
              </Link>
              <Link to="/teams/providers" className="tab-item">
                <IonSegmentButton value="providers" className="provider-button">
                  <IonText>{t('providers')}</IonText>
                </IonSegmentButton>
              </Link>
            </IonSegment>
          </IonCol>
        </IonRow>
        {[
          { name: 'new', component: <NewTeam /> },
          { name: 'teams', component: <TeamPage /> },
          { name: 'providers', component: <ProviderList /> },
        ].map((tab, key) => (
          <IonRow key={key} hidden={currentTab !== tab.name}>
            {tab.component}
          </IonRow>
        ))}
      </IonGrid>
    </>
  )
}

export default Teams
