import React from "react";

import { IonApp } from "@ionic/react";
import { BrowserRouter } from "react-router-dom";
import "moment/locale/th.js";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { Layout } from "theme";
import { Routes } from "./Routes";

const App: React.FC = () => {
  return (
    <React.Suspense fallback={<Layout />}>
      <BrowserRouter>
        <IonApp>
          <Routes />
        </IonApp>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
