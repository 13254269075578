import { createSlice } from "@reduxjs/toolkit";
import { IBaseState } from "redux/BaseState";
import { handleError } from "helpers/handleError";

export interface ITeam {
  _id: string;
  name: string;
  description: string;
  pictureUrl: string;
  members: [];
}

export interface ITeamState extends IBaseState {
  teams: ITeam[];
  team: ITeam | {};
  members: [];
}

const initialState: ITeamState = {
  teams: [],
  team: {},
  members: [],
  error: null,
  loading: false,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getTeamsSuccess: (state, { payload }) => {
      state.teams = payload;
      state.loading = false;
      state.error = null;
    },
    getTeamSuccess: (state, { payload }) => {
      state.team = payload;
      state.loading = false;
      state.error = null;
    },
    createTeamSuccess: (state, { payload }) => {
      state.team = payload;
      state.loading = false;
      state.error = null;
    },
    updateTeamSuccess: (state, { payload }) => {
      state.team = payload;
      state.loading = false;
      state.error = null;
    },
    getTeamMembersSuccess: (state, { payload }) => {
      state.members = payload;
      state.loading = false;
      state.error = null;
    },
    reset: (state) => {
      state.team = {};
      state.loading = false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = handleError(payload);
      state.loading = false;
    },
  },
});

export const {
  getTeamsSuccess,
  getTeamSuccess,
  createTeamSuccess,
  updateTeamSuccess,
  getTeamMembersSuccess,
  reset,
  setLoading,
  setError,
} = teamSlice.actions;

export const selectTeam = (state: any) => state.team;

export const selectTeamInfo = ({ team }: any) => {
  return {
    team: {
      name: team.team.name,
      description: team.team.description,
      previewPicture: team.team.pictureUrl,
      leader: team.team.leader,
      members: team.team.members || [],
      picture: team.team.picture,
    },
    loading: team.team.loading,
  };
};

export const selectTeams = ({ team }: any) => {
  return {
    teams: team.teams,
    totalPage: Math.ceil(team.teams.length / 30) || 0,
    loading: false,
  };
};

export default teamSlice.reducer;
