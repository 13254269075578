import { Dispatch } from 'redux'
import i18n from 'i18n'

import {
  getTeamsSuccess,
  getTeamSuccess,
  createTeamSuccess,
  updateTeamSuccess,
  getTeamMembersSuccess,
  setLoading,
  setError,
} from './teamSlice'
import { show as showAlert } from 'redux/alert/alertSlice'

import * as teamApi from './teamApi'

export const getTeams =
  (query: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading())

      const { data: teams } = await teamApi.getTeams(query)

      dispatch(getTeamsSuccess(teams))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const getTeamById =
  (teamId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading())

      const { data: team } = await teamApi.getTeamById(teamId)

      dispatch(getTeamSuccess(team))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const createTeam =
  (data: any, { resetForm }: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading())
      const { data: teamCreated } = await teamApi.createTeam(data)

      dispatch(createTeamSuccess(teamCreated))
      resetForm()
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.create-team-success'),
          redirect: '/teams/teams',
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const updateTeam =
  (teamId: string, data: any, { resetForm }: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading())
      const { data: teamUpdated } = await teamApi.updateTeam(teamId, data)

      dispatch(updateTeamSuccess(teamUpdated))
      resetForm()
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.edit-team-success'),
          redirect: `/teams/teams/${teamId}`,
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const getTeamMembers =
  (teamId: string, query: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading())

      const { data: members } = await teamApi.getTeamMembers(teamId, query)

      dispatch(getTeamMembersSuccess(members))
    } catch (error) {
      dispatch(setError(error))
    }
  }
