import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import ReportDetail from './ReportDetail'

import { getReportById } from '../redux/reportAction'
import { selectReport } from '../redux/reportSlice'
import { getJobById } from 'pages/Job/redux/jobAction'
import { selectJob } from 'pages/Job/redux/jobSlice'

import './ReportInfo.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  match: any
}

const ReportInfo = (props: IProps) => {
  const { t } = useTranslation(['report', 'common'])
  const { match } = props
  const reportId = match.params.reportId

  const dispatch = useDispatch()
  const { loading: jobLoading, job, wfJob } = useSelector(selectJob)
  const { loading: reportLoading, report } = useSelector(selectReport)

  useEffect(() => {
    dispatch(getReportById(reportId))
  }, [])

  useEffect(() => {
    if (!_.isEmpty(report)) {
      dispatch(getJobById(report.job._id))
    }
  }, [report])

  return (
    <IonPage>
      {reportLoading ? (
        <>Loading...</>
      ) : (
        <IonContent scrollX>
          <IonGrid class="job-info-header-content">
            <IonRow>
              <IonCol>
                <IonRouterLink routerLink="/reports">
                  <div className="teams-info-back-button display-flex">
                    <IonIcon
                      icon={chevronBack}
                      class="align-self-center icon-24"
                    />
                    <IonText class="align-self-center">{t('btn.back')}</IonText>
                  </div>
                </IonRouterLink>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonRow>
                  <IonLabel class="job-page-label">{wfJob.job.number}</IonLabel>
                  <IonLabel class="job-info-sub-headers left-space">
                    {wfJob.job.service.name}
                  </IonLabel>
                </IonRow>
              </IonCol>
              <IonCol>
                <div className="ion-text-right">
                  {t('created-on')}:{' '}
                  {moment(report.createdAt).format('DD MMM YYYY, HH:mm')}
                </div>
                <div className="ion-text-right">
                  {t('edited-on')}:{' '}
                  {moment(report.updatedAt).format('DD MMM YYYY, HH:mm')}
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <ReportDetail
            report={report}
            job={job}
            wfJob={wfJob}
            loading={jobLoading}
          />
        </IonContent>
      )}
    </IonPage>
  )
}

ReportInfo.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ReportInfo
