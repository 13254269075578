import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonInput,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
} from "@ionic/react";

import { login } from "./redux/authAction";
import { selectAuth } from "./redux/authSlice";

import "./Login.css";

const Login = () => {
  const [account, setAccount] = useState<any>({
    identifier: "",
    password: "",
    orgAccessKey: "",
  });
  const dispatch = useDispatch();
  const { error } = useSelector(selectAuth);

  const logginIn = async () => {
    dispatch(
      login({
        identifier: account.identifier,
        password: account.password,
        orgAccessKey: account.orgAccessKey,
      })
    );
  };

  const onChange = (e: any) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  return (
    <IonPage>
      <IonContent class="login-page-background">
        <IonGrid class="login-box">
          <IonRow>
            <IonLabel class="login-title">ลงชื่อเข้างานใช้ระบบองค์กร</IonLabel>
          </IonRow>
          <IonRow>
            <IonLabel class="login-field-headers">
              รหัสการเข้าถึงองค์กร
            </IonLabel>
          </IonRow>
          <IonRow>
            <IonInput
              name="orgAccessKey"
              type="text"
              class="login-fields"
              placeholder="รหัสการเข้าถึงองค์กร"
              value={account.orgAccessKey}
              onIonChange={onChange}
            ></IonInput>
          </IonRow>
          <IonRow>
            <IonLabel class="login-field-headers"> ลงชื่อเข้าใช้งาน </IonLabel>
          </IonRow>
          <IonRow>
            <IonInput
              name="identifier"
              type="text"
              class="login-fields"
              placeholder="อีเมล/เบอร์โทรศัพท์"
              value={account.identifier}
              onIonChange={onChange}
            ></IonInput>
          </IonRow>
          <IonRow>
            <IonLabel class="login-field-headers"> รหัสผ่าน </IonLabel>
          </IonRow>
          <IonRow>
            <IonInput
              name="password"
              type="password"
              class="login-fields"
              placeholder="รหัสผ่าน"
              value={account.password}
              onIonChange={onChange}
            ></IonInput>
          </IonRow>
          <IonRow hidden={!error}>
            <IonText class="reset-text text-red">
              อีเมลหรือรหัสผ่านไม่ถูกต้อง
            </IonText>
          </IonRow>
          <IonRow>
            <IonRouterLink href="/reset" class="reset-text">
              <IonText>หากคุณลืมรหัสผ่านคลิ๊กที่นี่</IonText>
            </IonRouterLink>
          </IonRow>
          <IonRow>
            <IonButton onClick={() => logginIn()} class="reset-button">
              <IonText>เข้าสู่ระบบ</IonText>
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
