import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment'
import 'moment/locale/th'

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'th',
    lng: localStorage.getItem('i18nextLng') || 'th',
    whitelist: ['en', 'th'],
    ns: 'translation',
    // defaultNS: 'translation',
    react: {
      wait: true,
      nsMode: 'fallback',
      useSuspense: false,
    },
  })
  .then(() => moment.locale(i18next.language))

export default i18next
