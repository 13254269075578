import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonAvatar, IonLabel } from "@ionic/react";

import { getOrgMembers } from "redux/org/orgAction";
import { selectOrgMembers } from "redux/org/orgSlice";
import { Datatable } from "components/Datatable";

import "./ProviderList.scss";
import ReadMore from "components/ReadMore";
import { useTranslation } from "react-i18next";
import { DateFormatter } from "components/formatters";

const Provider = () => {
  const { t } = useTranslation("table");
  const { loading, members, totalPage } = useSelector(selectOrgMembers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrgMembers({ role: "provider" }));
  }, [dispatch]);

  const columns = [
    {
      id: "fullName",
      numeric: false,
      disablePadding: true,
      label: t("provider-name"),
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: t("role"),
    },
    {
      id: "phoneNumber",
      numeric: false,
      disablePadding: false,
      label: t("contact"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("email"),
    },
    {
      id: "team",
      numeric: false,
      disablePadding: false,
      label: t("team"),
    },
    {
      id: "rating",
      numeric: false,
      disablePadding: false,
      label: t("rating"),
    },
    {
      id: "joinedAt",
      numeric: false,
      disablePadding: false,
      label: t("joined-date"),
    },
  ];

  const transformData = (items: any) => {
    return items.map((item: any) => {
      return {
        fullName: (
          <>
            <IonAvatar className="team-image-in-list">
              <img
                alt="preview"
                src={
                  item.avatarUrl ||
                  "https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png"
                }
              ></img>
            </IonAvatar>
            <IonLabel className="team-item">{item.fullName}</IonLabel>
          </>
        ),
        role: (
          <IonLabel className="align-self-center">{item.role.name}</IonLabel>
        ),
        phoneNumber: (
          <IonLabel className="align-self-center">{item.phoneNumber}</IonLabel>
        ),
        email: <IonLabel className="align-self-center">{item.email}</IonLabel>,
        team: (
          <IonLabel className="align-self-center">
            <ReadMore
              wording="see-more"
              isList
              list={item.teams.map(({ name }: { name: string }) => name)}
            />
          </IonLabel>
        ),
        rating: (
          <IonLabel className="align-self-center">
            {`${t("rating").toLocaleLowerCase()} ${item.rating}/5`}{" "}
          </IonLabel>
        ),
        joinedAt: (
          <IonLabel className="align-self-center">
            {/* {`${new Date(item.createdAt).toLocaleDateString('th-TH', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}`} */}
            <DateFormatter
              value={item.createdAt}
              format="ddd DD MMM YYYY, HH:mm"
            />
          </IonLabel>
        ),
      };
    });
  };

  return (
    <div className="tab-providers-list">
      {loading ? (
        <>Loading...</>
      ) : (
        <Datatable
          resource={getOrgMembers}
          query={{ role: "provider" }}
          columns={columns}
          data={transformData(members)}
          totalPage={totalPage}
          options={{ enabledSelection: false }}
        />
      )}
    </div>
  );
};

export default Provider;
