import { createSlice } from "@reduxjs/toolkit";

interface IAlertState {
  type: string | null;
  message: string | null;
  redirect?: string | null;
  isShow: boolean;
}

const initialState: IAlertState = {
  type: null,
  message: null,
  redirect: null,
  isShow: false,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    show: (state, { payload }) => {
      state.type = payload.type;
      state.message = payload.message;
      state.redirect = payload.redirect;
      state.isShow = true;
    },
    close: (state) => {
      state.type = null;
      state.message = null;
      state.redirect = null;
      state.isShow = false;
    },
  },
});

export const { show, close } = alertSlice.actions;

export const selectAlert = (state: any) => state.alert;

export default alertSlice.reducer;
