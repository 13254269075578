import { createSlice } from "@reduxjs/toolkit";
import { IBaseState } from "redux/BaseState";
import { handleError } from "helpers/handleError";

export interface IJob {
  _id: string;
  name: string;
  description: string;
  pictureUrl: string;
  members: [];
}

export interface IJobState extends IBaseState {
  paginate: {};
  job: IJob | {};
  wfJob: {
    job: {};
    jobProvider: {};
    comments: [];
  };
  members: [];
}

const initialState: IJobState = {
  paginate: {
    items: [],
    pageSize: 0,
    total: 0,
    totalPage: 0,
  },
  job: {
    customer: {},
    org: {},
    provider: { team: {} },
    team: {},
  },
  wfJob: {
    job: {
      service: {},
      customer: {},
      payout: {},
      location: {},
      inquiry: {
        attachments: [],
      },
      orders: [],
    },
    jobProvider: {
      answers: [],
      signature: {},
    },
    comments: [],
  },
  members: [],
  error: null,
  loading: false,
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    getJobsSuccess: (state, { payload }) => {
      state.paginate = payload;
      state.loading = false;
      state.error = null;
    },
    getJobSuccess: (state, { payload }) => {
      state.job = payload;
      state.error = null;
    },
    getWFJobSuccess: (state, { payload }) => {
      state.wfJob = payload;
      state.error = null;
    },
    commentJobSuccess: (state) => {
      state.loading = false;
    },
    assignJobToProviderSuccess: (state) => {
      state.loading = false;
    },
    assignJobToTeamSuccess: (state) => {
      state.loading = false;
    },
    bulkAssignJobSuccess: (state) => {
      state.loading = false;
    },
    getJobProviderSuccess: (state, { payload }) => {
      state.wfJob.jobProvider = payload;
      state.loading = false;
    },
    reset: (state) => {
      state.job = {};
      state.loading = false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = handleError(payload);
      state.loading = false;
    },
  },
});

export const {
  getJobsSuccess,
  getJobSuccess,
  getWFJobSuccess,
  commentJobSuccess,
  assignJobToProviderSuccess,
  assignJobToTeamSuccess,
  bulkAssignJobSuccess,
  getJobProviderSuccess,
  reset,
  setLoading,
  setError,
} = jobSlice.actions;

export const selectJob = (state: any) => state.job;
export const selectJobPagiante = ({ job }: any) => ({
  items: job.paginate.items,
  pageSize: job.paginate.pageSize,
  total: job.paginate.total,
  totalPage: Math.ceil(job.paginate.total / job.paginate.pageSize) || 0,
  loading: false,
});

export default jobSlice.reducer;
