import { BaseResource } from "./BaseResource";

export class TDResource extends BaseResource {
  constructor() {
    const accessToken: any = localStorage.getItem("accessToken");
    const BASE_API = `${process.env.REACT_APP_TD_BASE_API}`;

    super(accessToken, BASE_API);
  }
}
