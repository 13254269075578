import { createSlice } from "@reduxjs/toolkit";
import { IBaseState } from "redux/BaseState";
import { handleError } from "helpers/handleError";

export interface IReportState extends IBaseState {
  reports: {};
  report: {};
}

const initialState: IReportState = {
  reports: [],
  report: {},
  error: null,
  loading: false,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    getReportsSuccess: (state, { payload }) => {
      state.reports = payload;
      state.loading = false;
      state.error = null;
    },
    getReportSuccess: (state, { payload }) => {
      state.report = payload;
      state.error = null;
    },
    approveReportSuccess: (state, { payload }) => {
      state.error = null;
    },
    reset: (state) => {
      state.report = {};
      state.loading = false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = handleError(payload);
      state.loading = false;
    },
  },
});

export const {
  getReportsSuccess,
  getReportSuccess,
  reset,
  approveReportSuccess,
  setLoading,
  setError,
} = reportSlice.actions;

export const selectReport = (state: any) => state.report;
export const selectReports = ({ report }: any) => ({
  reports: report.reports,
  totalPage: Math.ceil(report.reports.length / 30) || 0,
  loading: false,
});

export default reportSlice.reducer;
