import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonText,
  IonIcon,
} from '@ionic/react'
import { newspaper } from 'ionicons/icons'

import { getReports } from './redux/reportAction'
import { selectReports } from './redux/reportSlice'
import { Datatable } from 'components/Datatable'
import { DateTimeFormatter } from 'components/formatters'
import StateTag from 'components/StateTag'

import './ReportPage.scss'
import { useTranslation } from 'react-i18next'

const ReportPage = () => {
  const { loading, reports, totalPage } = useSelector(selectReports)
  const { t } = useTranslation(['report', 'table', 'common'])
  const columns = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: t('job-id'),
    },
    {
      id: 'startTime',
      numeric: false,
      disablePadding: false,
      label: t('start-date'),
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: t('report.description'),
    },
    {
      id: 'completedAt',
      numeric: false,
      disablePadding: false,
      label: t('completed-on'),
    },
    {
      id: 'approvedBy',
      numeric: false,
      disablePadding: false,
      label: t('approved-by'),
    },
    {
      id: 'position',
      numeric: false,
      disablePadding: false,
      label: t('position'),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('job-status'),
    },
  ]

  const transformData = (items: any) => {
    return items.map((item: any) => {
      let approvedBy = ''
      let position = ''

      if (item.approvers.length > 0) {
        const latestIndex = item.approvers.length - 1
        approvedBy = item.approvers[latestIndex].approver.fullName
        position = item.approvers[latestIndex].type
      }

      return {
        number: (
          <Link to={`/jobs/${item?.job?._id}`} className="item-text-black">
            {item.number}
          </Link>
        ),
        startTime: <DateTimeFormatter value={item.startedAt} />,
        description: (
          <div className="item">
            <IonIcon class="ion-icon" icon={newspaper} />
            <Link to={`/reports/${item._id}`}>
              <IonText class="item-text">{`Job ${item.number}`}</IonText>
            </Link>
          </div>
        ),
        completedAt: <DateTimeFormatter value={item.completedAt} />,
        approvedBy,
        position,
        status: <StateTag value={item.status} round={false} />,
      }
    })
  }

  return (
    <>
      <IonGrid class="job-header-content">
        <IonRow>
          <IonCol>
            <IonLabel class="job-page-label">
              <IonText>{t('title.report')}</IonText>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText>{t('title.report-sub-title')}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid class="job-page-content">
        {loading ? (
          <>Loading...</>
        ) : (
          <Datatable
            resource={getReports}
            columns={columns}
            data={transformData(reports)}
            totalPage={totalPage}
            options={{ enabledSelection: false }}
          />
        )}
      </IonGrid>
    </>
  )
}

export default ReportPage
