import PropTypes from 'prop-types'

const PhoneFormatter = ({ phoneNumber }) => {
  const format = () => {
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (!match) return phoneNumber

    return `${match[1]} ${match[2]} ${match[3]}`
  }

  return <>{format()}</>
}

export default PhoneFormatter

PhoneFormatter.propTypes = {
  phoneNumber: PropTypes.string,
}
