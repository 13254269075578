import {
  IonMenu,
  // IonHeader,
  // IonToolbar,
  // IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonMenuToggle,
} from '@ionic/react'
import { home, people, briefcase, newspaper } from 'ionicons/icons'
import React, { createRef } from 'react'
import { useTranslation } from 'react-i18next'
import './GlobalMenu.css'

const GlobalMenu: React.FC = () => {
  const { t } = useTranslation('menu')

  const menuRef = createRef() as React.MutableRefObject<HTMLIonMenuElement>

  return (
    <IonMenu side="start" ref={menuRef} contentId="main">
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        <IonList lines="none">
          <IonMenuToggle autoHide={false}>
            <IonItem routerLink="/dashboard" routerDirection="root">
              <IonIcon icon={home} slot="start"></IonIcon>
              <IonLabel>{t('home')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              button={true}
              routerLink={'/teams/new'}
              routerDirection="root"
            >
              <IonIcon icon={people} slot="start"></IonIcon>
              <IonLabel>{t('teams')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem button={true} routerLink={'/jobs'} routerDirection="root">
              <IonIcon icon={briefcase} slot="start"></IonIcon>
              <IonLabel>{t('jobs')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle autoHide={false}>
            <IonItem
              button={true}
              routerLink={'/reports'}
              routerDirection="root"
            >
              <IonIcon icon={newspaper} slot="start"></IonIcon>
              <IonLabel>{t('reports')}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default GlobalMenu
