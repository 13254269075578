import { IonButton, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonRow, IonText, IonTitle } from "@ionic/react";
import { mailUnreadOutline } from "ionicons/icons";
import React, { useState } from "react";
import configs from "../../components/config";

import './Reset.css'
const Reset: React.FC = () => {

    const [email, setEmail] = useState<string>('');
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const sendResetEmail = () => {
        const basicToken = btoa(`${configs.reset_username}:${configs.reset_password}`);
        const resetEmail = email;
        const fetchMethod = fetch(`${configs.reset_endpoint}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${basicToken}`
            },
            body: JSON.stringify({
                "email": resetEmail
            })
        });
        (async() => {
            const result = await fetchMethod;
            if (result.status === 200 && resetEmail !== '') {
                setShowSuccess(true);
            }
        })()
    }

    return (
        <IonPage>
            <IonContent class="login-page-background">
                <IonGrid class="login-box" hidden={showSuccess}>
                    <IonRow>
                        <IonLabel class="login-title">
                            รีเซ็ตรหัสผ่านบัญชี
                        </IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonLabel class="reset-subheader">
                            โดยการใช้อีเมล
                        </IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonLabel class="login-field-headers">
                            อีเมล
                        </IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonInput class="login-fields" placeholder="example@gmail.com" type="email" value={email} onIonChange={(e) => setEmail(e.detail.value || '')}></IonInput>
                    </IonRow>
                    <IonRow>
                        <IonButton onClick={sendResetEmail} class="reset-button">
                            <IonText>รีเซ็ตรหัสผ่าน</IonText>
                        </IonButton>
                    </IonRow>
                </IonGrid>
                <IonGrid class="login-box" hidden={!showSuccess}>
                    <IonRow>
                        <IonLabel class="login-title">รีเซ็ตรหัสผ่านบัญชี</IonLabel>
                    </IonRow>
                    <IonRow>
                        <IonLabel class="reset-subheader">อีเมลถูกส่งไปให้คุณแล้ว</IonLabel>
                    </IonRow>
                    <IonRow class="unread-row">
                        <IonIcon icon={mailUnreadOutline} class="unread-icon"/>
                        <IonText class="email-font">
                            {email}
                        </IonText>
                    </IonRow>
                    <IonRow>
                        <IonText class="reset-text">
                            กรุณาเช็คอีเมล {email} และทำตามขั้นตอนที่ระบุในอีเมล เพื่อตั้งค่ารหัสผ่านใหม่
                        </IonText>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Reset;