import { IonText } from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './ReadMore.scss'

interface ReadMoreProps {
  children?: string
  list?: any[]
  wording?: string
  limit?: number
  isList?: boolean
}

const ReadMore: React.FC<ReadMoreProps> = ({
  children,
  wording = 'read-more',
  limit = 100,
  list = [],
}) => {
  const text = children || ''
  const isArray = Array.isArray(list) && list.length > 0
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('common')

  const handleToggleReadMore = () => {
    setIsOpen(!isOpen)
  }

  if (isArray && list?.length > 0) {
    return (
      <ul>
        {list?.slice(0, 2).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
        {list.length > 2 ? (
          isOpen ? (
            list
              .slice(2, list.length)
              .map((item, index) => <li key={index}>{item}</li>)
          ) : (
            <IonText
              onClick={handleToggleReadMore}
              className="expand-text"
              color="primary"
            >{`${t(wording)}`}</IonText>
          )
        ) : null}
      </ul>
    )
  }

  return (
    <span>
      {isOpen ? text : text.slice(0, limit)}
      <span onClick={handleToggleReadMore} className="expand-text">
        {isOpen ? (
          ''
        ) : text.length > limit ? (
          <IonText color="primary">{` ...${t(wording)}`}</IonText>
        ) : (
          ''
        )}
      </span>
    </span>
  )
}

export default ReadMore
