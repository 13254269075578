import { Dispatch } from 'redux'

import {
  getNotifications as getNotificationsApi,
  readNotification,
} from './notificationApi'
import { setNotifications } from './notificationSlice'

export const getNotificationsAction =
  (query?: any): any =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await getNotificationsApi(query)
      dispatch(setNotifications(data))
    } catch (error) {}
  }

export const readNotificationAction =
  (notificationId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      await readNotification(notificationId)
    } catch (error) {}
  }
