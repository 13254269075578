import classNames from 'clsx'
import { useTranslation } from 'react-i18next'

import './StateTag.scss'

export function StateTag({ value, round, right = false }) {
  const { t } = useTranslation('state')
  const translate = (value) => {
    switch (value) {
      case 'cancelled':
        return t('cancelled')

      case 'completed':
        return t('completed')

      case 'expired':
        return t('expired')

      case 'pending_completion':
        return t('pending_completion')

      case 'pending_provider':
        return t('pending_provider')

      case 'pending_payment':
        return t('pending_payment')

      case 'pending_start':
        return t('pending_start')

      case 'pending_assign':
        return t('pending_assign')

      case 'pending_review':
        return t('pending_review')

      case 'pending_approval':
        return t('pending_approval')

      case 'rejected':
        return t('rejected')

      case 'assign_team':
        return t('assign_team')

      default:
        return value
    }
  }
  return (
    <span
      style={right ? { float: 'right' } : {}}
      className={classNames([round ? 'state-tag-round' : 'state-tag', value])}
    >
      {translate(value)}
    </span>
  )
}

export default StateTag
