import axios from 'axios'

export class BaseResource {
  instance: any
  accessToken: string
  baseAPI: string
  constructor(accessToken: string, baseAPI: string) {
    this.accessToken = accessToken
    this.baseAPI = baseAPI
    this.instance = null
  }

  getInstance() {
    if (this.instance) return this.instance

    const instance = axios.create({
      baseURL: this.baseAPI,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.accessToken}`,
      },
    })

    this.instance = instance

    return instance
  }
}
