import { createSlice } from '@reduxjs/toolkit'
import { IBaseState } from 'redux/BaseState'
import { handleError } from 'helpers/handleError'

export interface INotificationState extends IBaseState {
  notifications: []
  total: number
}

const initialState: INotificationState = {
  notifications: [],
  total: 0,
  error: null,
  loading: false,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    setError: (state, { payload }) => {
      state.error = handleError(payload)
    },
    setNotifications: (state, { payload }) => {
      state.notifications = payload.notifications
      state.total = payload.total
      state.loading = false
    },
  },
})

export const { setLoading, setError, setNotifications } =
  notificationSlice.actions

export const selectNotification = (state: any) => state.notification

export default notificationSlice.reducer
