import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'

import { DateFormatter } from 'components/formatters'
import MemberList from './MemberList'
import { getTeamById, getTeamMembers } from 'pages/Team/redux/teamAction'
import { selectTeam } from 'pages/Team/redux/teamSlice'

import './TeamInfo.scss'
import { useTranslation } from 'react-i18next'

const TeamsInfo = () => {
  const { teamId } = useParams<any>()
  const dispatch = useDispatch()
  const { loading, team, members } = useSelector(selectTeam)
  const [search, setSearch] = useState('')
  const { t } = useTranslation(['common', 'team', 'table', 'placeholder'])

  useEffect(() => {
    dispatch(getTeamById(teamId))
    dispatch(getTeamMembers(teamId, {}))
  }, [])

  useEffect(() => {
    initTeams()
  }, [dispatch])

  const initTeams = () => {
    dispatch(getTeamMembers(teamId, {}))
  }

  const handleKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      entry()
    }
  }

  const entry = () => {
    dispatch(getTeamMembers(teamId, { search }))
  }

  const onSearch = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <IonPage>
      <IonContent scrollX>
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            <IonGrid className="teams-info-header-content">
              <IonRow>
                <IonCol>
                  <Link to="/teams/teams" className="link-item">
                    <div className="teams-info-back-button display-flex">
                      <IonIcon
                        icon={chevronBack}
                        className="align-self-center icon-24"
                      />
                      <IonText className="align-self-center">
                        {t('back-btn')}
                      </IonText>
                    </div>
                  </Link>
                </IonCol>
              </IonRow>
              <IonRow className="team-info-header-content">
                <IonCol size="2">
                  <IonAvatar className="team-img-full">
                    <img src={team.pictureUrl} alt="preview" />
                  </IonAvatar>
                </IonCol>
                <IonCol size="8">
                  <IonRow>
                    <IonCol
                      className="team-info-header-team-name-label"
                      size="3"
                    >
                      {`${t('team')} ${team.name}`}
                    </IonCol>
                    <IonCol size="9">{`${team.description}`}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="team-info-header-team-info" size="3">
                      {t('team-created-on')}
                    </IonCol>
                    <IonCol className="team-info-header-team-info" size="9">
                      <DateFormatter value={team.createdAt} />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="team-info-header-team-info" size="3">
                      {t('team-members')}
                    </IonCol>
                    <IonCol className="team-info-header-team-info" size="9">
                      {`${team.members?.length}`}
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol size="2">
                  <Link to={{ pathname: `/teams/teams/${teamId}/edit` }}>
                    <IonButton className="team-info-edit-team-info">
                      {t('team-edit-btn')}
                    </IonButton>
                  </Link>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className="team-info-content">
              <IonRow>
                <IonCol className="teams-info-teams-search-col">
                  <IonSearchbar
                    className="teams-info-teams-search-bar"
                    onKeyUp={handleKeyUp}
                    placeholder={t(
                      'searchbar-name-and-phonenumber-placeholder'
                    )}
                    onIonChange={onSearch}
                    onIonClear={initTeams}
                    value={search}
                  ></IonSearchbar>
                  <IonButton
                    className="teams-info-teams-search-button"
                    onClick={() => entry()}
                  >
                    <IonText>{t('search-btn')}</IonText>
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonContent className="teams-info-providers-list" scrollX>
                  <IonGrid className="provider-list">
                    <IonRow className="teams-list-header">
                      <IonCol size="2">
                        <IonLabel>{t('provider-name')}</IonLabel>
                      </IonCol>
                      <IonCol size="1">
                        <IonLabel>{t('role')}</IonLabel>
                      </IonCol>
                      <IonCol size="1">
                        <IonLabel>{t('contact')}</IonLabel>
                      </IonCol>
                      <IonCol size="2"></IonCol>
                      <IonCol size="2">
                        <IonLabel>{t('team')}</IonLabel>
                      </IonCol>
                      <IonCol size="1">
                        <IonLabel>{t('rating')}</IonLabel>
                      </IonCol>
                      <IonCol size="2">
                        <IonLabel>{t('joined-date')}</IonLabel>
                      </IonCol>
                    </IonRow>
                    <MemberList
                      members={members}
                      team={team.name}
                      leader={team.leader}
                    />
                  </IonGrid>
                </IonContent>
              </IonRow>
            </IonGrid>
          </>
        )}
      </IonContent>
    </IonPage>
  )
}

export default TeamsInfo
