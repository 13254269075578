import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  IonTextarea,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CommentCard from 'components/CommentCard'
import { commentJob } from '../../redux/jobAction'

interface IProps {
  wfJob: any
  toggleReloadPage: any
}

const JobComment = ({ wfJob, toggleReloadPage }: IProps) => {
  const { t } = useTranslation(['common'])
  const [commentMessage, setCommentMessage] = useState('')
  const dispatch = useDispatch()

  const postComment = () => {
    dispatch(commentJob(wfJob.job.id, commentMessage))
  }

  return (
    <IonGrid class="job-info-content-grid">
      <IonRow class="margin-top-16">
        <IonCol>
          <IonRow>
            <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
              {t('comment')}
            </IonLabel>
          </IonRow>

          <IonRow class="margin-top-8">
            <IonTextarea
              rows={15}
              cols={20}
              placeholder={t('comment-description')}
              value={commentMessage}
              class="text-area-comment"
              onIonChange={(e) => setCommentMessage(e.detail.value!)}
            ></IonTextarea>
          </IonRow>

          <IonButton
            onClick={() => {
              postComment()
              toggleReloadPage()
            }}
          >
            <IonText>{t('btn.comment')}</IonText>
          </IonButton>
        </IonCol>
        <IonCol>
          <IonRow>
            <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
              {' '}
              {t('comments')}{' '}
            </IonLabel>
          </IonRow>
          <IonList class="list-scorll">
            {wfJob.comments.map((comment: any, key: any) => (
              <IonRow key={key} class="margin-top-8">
                <CommentCard value={comment} />
              </IonRow>
            ))}
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

JobComment.propTypes = {
  wfJob: PropTypes.any,
}

export default JobComment
