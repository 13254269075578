import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export class DateFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.node.isRequired,
    simple: PropTypes.bool,
    full: PropTypes.bool,
    format: PropTypes.string,
  }

  static defaultProps = {
    simple: false,
    full: false,
  }

  render() {
    const { value, simple, full, format, ...rest } = this.props

    if (format) return moment(value).format(format)

    if (full) {
      return <span {...rest}>{moment(value).format('DD MMMM YYYY')}</span>
    } else {
      return (
        <span {...rest}>
          {moment(value).format(simple ? 'D MMM YYYY' : 'ddd, DD MMM YYYY')}
        </span>
      )
    }
  }
}

export default DateFormatter
