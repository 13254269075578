import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  IonToolbar,
  IonIcon,
  IonHeader,
  IonTitle,
  IonLabel,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonBadge,
  IonText,
  IonMenuToggle,
  IonRippleEffect,
} from '@ionic/react'
import { menu, notifications, personCircle } from 'ionicons/icons'

import moment from 'moment'

import { getNotifications as getNotificationsApi } from 'redux/notification/notificationApi'
import UserDropdown from './UserDropdown/UserDropdown'
import Organization from './Organization/Organization'
import NotificationListPopover from 'components/NotificationListPopover'

import './GlobalToolbar.css'

interface NotiState {
  notificationList: any[]
  totalNoti: number
}

interface GloblToolbarProps {
  handleMenuOpen: () => void
}

const GlobalToolbar: React.FC<GloblToolbarProps> = (props) => {
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const [popoverState, setPopoverState] = useState({
    showPopover: false,
    event: undefined,
  })
  const [notiState, setNotiState] = useState<NotiState>({
    notificationList: [],
    totalNoti: 0,
  })
  const [loading, setLoading] = useState(false)
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false)
  const {
    i18n: { language, changeLanguage },
  } = useTranslation('common')

  const { org } = useSelector((state: any) => state.org)

  useEffect(() => {
    fetchNotification()
  }, [])

  const fetchNotification = async (scroll?: any) => {
    setLoading(true)
    const { data } = await getNotificationsApi({
      isRead: true,
      page: 1,
      pageSize: 10,
    })
    setNotiState({
      notificationList: scroll
        ? [...notiState.notificationList, ...data.notifications]
        : data.notifications,
      totalNoti: data.total,
    })
    setInfiniteDisabled(data.total < 10)
    setLoading(false)
  }

  const handleShowNotification = (e: any) => {
    e.persist()
    setPopoverState({
      showPopover: true,
      event: e,
    })
  }

  const handleCloseNotification = () => {
    setPopoverState({
      showPopover: false,
      event: undefined,
    })
  }

  const handleLanguageChange = (lang: string) => {
    changeLanguage(lang)
    moment.locale(lang)
    localStorage.setItem('i18nextLng', lang)
    window.location.reload()
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="toolbar-blue">
          <IonButtons
            slot="start"
            onClick={props.handleMenuOpen}
            className="ion-activatable ripple-parent split-pane-menu-button"
          >
            <IonIcon icon={menu} />
            <IonRippleEffect />
          </IonButtons>
          <IonButtons slot="start" className="ion-menu-button-ion-button">
            <IonMenuButton autoHide={false} />
          </IonButtons>
          <IonTitle>
            {org?.pictureUrl ? (
              <Organization orgName={org?.name} orgUrl={org?.pictureUrl} />
            ) : (
              '...'
            )}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              id="click-notification-trigger"
              onClick={handleShowNotification}
            >
              <IonIcon icon={notifications} />
              {notiState.totalNoti > 0 && (
                <IonBadge color="danger">{notiState.totalNoti}</IonBadge>
              )}
            </IonButton>
            <NotificationListPopover
              isOpen={popoverState.showPopover}
              event={popoverState.event}
              handleCloseNotification={handleCloseNotification}
              notificationList={notiState.notificationList}
              loading={loading}
              fetchNotification={fetchNotification}
              isInfiniteDisabled={isInfiniteDisabled}
              total={notiState.totalNoti}
            />

            <IonButton
              onClick={() => {
                setShowUserDropdown(!showUserDropdown)
              }}
            >
              <IonIcon icon={personCircle}></IonIcon>
            </IonButton>
            <IonLabel>Organization </IonLabel>
            <IonLabel>
              {' '}
              <IonText
                className={`change-lang ${
                  language.includes('en') ? 'selected-lang' : ''
                }`}
                onClick={() => handleLanguageChange('en')}
              >
                En
              </IonText>{' '}
              /{' '}
              <IonText
                className={`change-lang ${
                  language === 'th' ? 'selected-lang' : ''
                }`}
                onClick={() => handleLanguageChange('th')}
              >
                ภาษาไทย
              </IonText>
            </IonLabel>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {showUserDropdown && <UserDropdown />}
    </>
  )
}

export default GlobalToolbar
