import React from "react";
import { IonList, IonItem, IonLabel, IonInput } from "@ionic/react";

export function QuestionsAndAnswers({ answers = [] }) {
  function displayAnswer(obj) {
    const question = obj.question;
    const choice = obj.choice;
    const choices = obj.choices;
    const attachments = obj.attachments;

    switch (question.display_type) {
      case "radio":
        return choice.value || obj.display_value;
      case "string":
        return obj.value || obj.display_value;
      case "number":
        return obj.value || obj.display_value;
      case "textarea":
        return obj.value || obj.display_value;
      case "select":
        return (choice && choice.value) || obj.display_value;
      case "searchable_select":
        return (choice && choice.value) || obj.display_value;
      case "gallery":
        return (
          <img
            src={obj.image_url}
            style={{ height: 200, width: 200 }}
            alt="answerImage"
          />
        );
      case "camera":
        return (
          <img
            src={obj.image_url}
            style={{ height: 200, width: 200 }}
            alt="answerImage"
          />
        );
      case "gallery_multiple":
        return attachments.map((attachment, index) => {
          return (
            <img
              key={index}
              style={{ height: 200, width: 200 }}
              src={attachment.file_url}
              alt="answerImage"
            />
          );
        });
      case "camera_multiple":
        return attachments.map((attachment, index) => {
          return (
            <img
              key={index}
              style={{ height: 200, width: 200 }}
              src={attachment.file_url}
              alt="answerImage"
            />
          );
        });
      case "checkboxes":
        return choices.size > 0
          ? choices.map((item, index) => {
              return (
                <ul key={index}>
                  <li>{item.value}</li>
                </ul>
              );
            })
          : obj.display_value;

      default:
        break;
    }
  }

  return (
    <div>
      {answers.map((answer, index) => {
        const question = answer.question;

        return (
          <IonList key={index}>
            <IonItem>
              <IonLabel position="floating">{question.name}</IonLabel>
              <IonInput slot="end" disabled>
                {displayAnswer(answer)}
              </IonInput>
            </IonItem>
          </IonList>
        );
      })}
    </div>
  );
}

export default QuestionsAndAnswers;
