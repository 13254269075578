import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Pagination from '@mui/material/Pagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'

import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import Search from './Search'

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  columns: []
  enabledSelection: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7FBFF',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    fontSize: 13.5,
    padding: '20px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13.5,
  },
}))

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount, columns, enabledSelection } =
    props

  return (
    <TableHead>
      <TableRow>
        {enabledSelection && (
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </StyledTableCell>
        )}
        {columns.map((column: any, key: any) => (
          <StyledTableCell
            key={key}
            align="left"
            padding={column.disablePadding ? 'none' : 'normal'}
          >
            {column.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function Datatable({
  resource,
  columns,
  data,
  totalPage,
  toolbar,
  onSelected,
  options,
  limit,
  query,
}: any) {
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  options = {
    enabledSelection: true,
    ...options,
  }

  useEffect(() => {
    dispatch(resource({ search, page, pageSize: limit, ...query }))
  }, [search, page])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selected.length === 0) {
      const newSelecteds = data
        .filter(
          (i: any) =>
            i?.data?.status === 'pending_assign' && i?.data?.team === null
        )
        .map((n: any) => n._id)
      setSelected(newSelecteds)
      return newSelecteds
    }
    setSelected([])
    return []
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)

    return newSelected
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const onSearch = (text: string) => {
    setSearch(text)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Search text={search} onSearch={onSearch} />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} toolbar={toolbar} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={(e) => onSelected(handleSelectAllClick(e))}
              rowCount={data.length}
              columns={columns}
              enabledSelection={options.enabledSelection}
            />
            <TableBody>
              {data.map((row: any, index: any) => {
                const isItemSelected = isSelected(row._id)
                const labelId = `enhanced-table-checkbox-${index}`
                const isRead = row?.data?.isRead || row?.isRead

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    className={`table-row ${isRead ? 'read' : ''}`}
                  >
                    {options.enabledSelection && (
                      <TableCell padding="checkbox">
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Checkbox
                            // style={{ width: '100%' }}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) =>
                              onSelected(handleClick(event, row._id))
                            }
                            disabled={
                              row?.data?.status !== 'pending_assign' ||
                              row?.data?.team !== null
                            }
                          />
                        </div>
                      </TableCell>
                    )}
                    {Object.keys(row).map((r: any, key: any) => {
                      return (
                        r !== '_id' &&
                        r !== 'data' && (
                          <TableCell key={key} align="left">
                            {row[r]}
                          </TableCell>
                        )
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box height="100%" display="flex" justifyContent="center">
        <Pagination
          count={totalPage}
          page={page}
          color="primary"
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value)
          }}
        />
      </Box>
    </Box>
  )
}
