import React, { useEffect } from 'react'
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  IonText,
  IonToolbar,
} from '@ionic/react'

import './NotificationListPopover.scss'
import {
  briefcaseOutline,
  newspaperOutline,
  notificationsOutline,
} from 'ionicons/icons'
import { DateFormatter } from 'components/formatters'
import { useHistory } from 'react-router'
import { readNotificationAction } from 'redux/notification/notificationAction'
import { useDispatch } from 'react-redux'

interface Props {
  handleCloseNotification: () => void
  fetchNotification: (isScroll?: boolean) => void
  isOpen: boolean
  event: any
  loading: boolean
  isInfiniteDisabled: boolean
  notificationList?: any[]
  total?: number
}

const NotificationListPopover = (props: Props) => {
  const {
    isOpen,
    event,
    notificationList = [],
    total = 0,
    handleCloseNotification,
    fetchNotification,
    isInfiniteDisabled,
  } = props

  const history = useHistory()
  const dispatch = useDispatch()

  const handleInfiniteScroll = (e: any) => {
    fetchNotification(true)
    e.target.complete()
  }

  const handleClick = (notification: any) => {
    dispatch(readNotificationAction(notification._id))
      .then(() => {
        if (notification.job) {
          history.push(`/jobs/${notification.job._id}`)
        } else if (notification.report) {
          history.push(`/reports/${notification.report._id}`)
        }
      })
      .then(() => fetchNotification())
      .catch(() => {})
      .finally(() => {
        handleCloseNotification()
      })
  }

  return (
    <IonPopover
      isOpen={isOpen}
      event={event}
      cssClass="notification-list-popover"
      onDidDismiss={handleCloseNotification}
      showBackdrop={false}
    >
      <IonContent>
        <IonList lines="full">
          <IonListHeader
            lines="full"
            className="ion-padding-end ion-padding-start"
          >
            <IonLabel
              style={{
                marginTop: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h2>
                <strong>รายการแจ้งเตือน</strong>{' '}
              </h2>
              <IonText color="primary">
                <h6>{total} รายการ</h6>
              </IonText>
            </IonLabel>
          </IonListHeader>
          {notificationList.map((notification, index) => (
            <IonItem onClick={() => handleClick(notification)}>
              <IonIcon
                slot="start"
                icon={
                  notification.job
                    ? briefcaseOutline
                    : notification.report
                    ? newspaperOutline
                    : notificationsOutline
                }
              />
              <IonLabel>
                <IonText>
                  <strong>
                    {notification.report && 'Report: '}
                    {notification?.job?.number || notification?.report?.number}
                  </strong>
                </IonText>
                <div>
                  <IonText color="primary">
                    <h6>
                      <DateFormatter value={notification.date} />
                    </h6>
                  </IonText>
                </div>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={handleInfiniteScroll}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          />
        </IonInfiniteScroll>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonLabel className="ion-text-center">
            <IonText color="primary">
              <u>
                <p>ดูเพิ่มเติม</p>
              </u>
            </IonText>
          </IonLabel>
        </IonToolbar>
      </IonFooter>
    </IonPopover>
  )
}

export default NotificationListPopover
