import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  IonTextarea,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CommentCard from 'components/CommentCard'
import { commentJob } from '../../redux/jobAction'

import './JobDetail.scss'

interface IProps {
  job: any
  wfJob: any
  loading: boolean
  setTapSelected: any
  toggleReloadPage?: () => void
}

const JobInfo = ({
  loading,
  job,
  wfJob,
  setTapSelected,
  toggleReloadPage = () => {},
}: IProps) => {
  const dispatch = useDispatch()
  const [commentMessage, setCommentMessage] = useState('')
  const { t } = useTranslation(['common', 'placeholder'])

  const postComment = () => {
    dispatch(commentJob(wfJob.job.id, commentMessage))
  }

  const additionalAttachment = (attachments: any) => {
    if (attachments && attachments.length > 0) {
      return (
        <IonGrid>
          <IonRow>
            {attachments.map((attachment: any, key: any) => (
              <IonCol key={key} size="3">
                <img src={attachment.file_url} alt={attachment.id} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )
    } else {
      return '-'
    }
  }

  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <IonGrid class="job-info-content-grid">
          <IonRow>
            <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
              Map
            </IonLabel>
          </IonRow>
          <IonRow>
            <IonCol>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  frameBorder="0"
                  scrolling="no"
                  title="iframe"
                  src={
                    job &&
                    `https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${wfJob.job.location.latitude},${wfJob.job.location.longitude}+(Job)&t=&z=14&ie=UTF8&iwloc=B&output=embed`
                  }
                ></iframe>
              </div>
            </IonCol>
          </IonRow>
          <IonRow class="margin-top-16">
            <IonCol>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('orders')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonLabel>{wfJob.job.description}</IonLabel>
              </IonRow>
            </IonCol>
            <IonCol>
              <IonLabel class="float-right">
                <div>
                  <IonLabel class="date-text-number">
                    {moment(job.startTime).format('D')}
                  </IonLabel>
                  <IonLabel class="date-time-text-number">
                    {moment(job.startTime).format('MMMM YYYY')}{' '}
                  </IonLabel>
                </div>
                <div className="time-ragne-container">
                  <IonLabel>
                    {t('service-duration')}{' '}
                    {moment(wfJob.job.end_time).diff(
                      moment(wfJob.job.start_time),
                      'hours'
                    )}{' '}
                    {t('hour-unit')}
                  </IonLabel>
                </div>
                <div className="time-container">
                  <IonLabel class="start-time-number">{`${t(
                    'start-at'
                  )} ${moment(wfJob.job.start_time).format(
                    'HH:mm'
                  )}`}</IonLabel>
                  <IonLabel>{`${t('will-finish-at')} ${moment(
                    wfJob.job.end_time
                  ).format('HH:mm')}`}</IonLabel>
                </div>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow class="margin-top-8">
            <IonGrid>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('contact-detail')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonText>{wfJob.job.customer.full_name}</IonText>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonText>{wfJob.job.customer.phone_number}</IonText>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonText>{wfJob.job.location.full_address}</IonText>
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="margin-top-16">
            <IonGrid>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('additional-details')}
                </IonLabel>
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="margin-top-16">
            <IonGrid>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('title.images-from-customer')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                {additionalAttachment(wfJob.job.inquiry.attachments)}
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="margin-top-16">
            <IonCol>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('title.comments')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                {wfJob.comments.length === 0 ? (
                  <IonTextarea
                    rows={7}
                    cols={20}
                    placeholder={t('comment-description')}
                    value={commentMessage}
                    class="text-area-comment"
                    onIonChange={(e) => setCommentMessage(e.detail.value!)}
                  ></IonTextarea>
                ) : (
                  <CommentCard value={wfJob.comments[0]} />
                )}
              </IonRow>
              <IonButton
                onClick={() => {
                  if (wfJob.comments.length === 0) {
                    postComment()
                    toggleReloadPage()
                  } else {
                    setTapSelected('comments')
                  }
                }}
              >
                <IonText>{t('btn.comment')}</IonText>
              </IonButton>
            </IonCol>
            <IonCol />
          </IonRow>
        </IonGrid>
      )}
    </>
  )
}

JobInfo.propTypes = {
  job: PropTypes.any,
  wfJob: PropTypes.any,
  loading: PropTypes.bool,
  setTapSelected: PropTypes.any,
}

export default JobInfo
