import { createSlice } from '@reduxjs/toolkit'
import { IBaseState } from 'redux/BaseState'
import { handleError } from 'helpers/handleError'

interface IOrg {
  _id: string
  name: string
  email: string
  information: string
  phoneNumber: string
  address: string
  isRequireAdminApprove: boolean
  isRequireOrgApprove: boolean
  accessKey: string
  number: number
  pictureUrl: string
}

export interface ITeam {
  _id: string
  name: string
  description: string
  pictureUrl: string
}

export interface IUser {
  _id: string
  avatarUrl: string
  username: string
  email: string
  wfUserId: number
  fullName: string
  phoneNumber: number
  rating: number
  isActived: boolean
  wfAccessToken: string
  org: IOrg
  teams: ITeam[]
}

export interface IAuthState extends IBaseState {
  isLoggedIn: boolean
  user: IUser | null
  accessToken: string | null
}

const initialState: IAuthState = {
  isLoggedIn: false,
  user: null,
  accessToken: null,
  error: null,
  loading: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    loggedIn: (state, { payload }) => {
      const { user, accessToken } = payload

      state.user = user
      state.accessToken = accessToken
      state.isLoggedIn = true
      state.loading = false
      state.error = null
    },
    loggedOut: (state) => {
      state.user = null
      state.accessToken = null
      state.isLoggedIn = false
      state.loading = false
      state.error = null
    },
    setError: (state, { payload }) => {
      state.error = handleError(payload)
      state.loading = false
    },
  },
})

export const { loggedIn, loggedOut, setLoading, setError } = authSlice.actions

export const selectAuth = (state: any) => state.auth

export default authSlice.reducer
