import { createSlice } from "@reduxjs/toolkit";
import { IBaseState } from "redux/BaseState";
import { handleError } from "helpers/handleError";

export interface IDashboardState extends IBaseState {
  jobCount: {
    all: number;
    pendingAssign: number;
    completed: number;
    incoming: number;
  };
}

const initialState: IDashboardState = {
  jobCount: {
    all: 0,
    pendingAssign: 0,
    completed: 0,
    incoming: 0,
  },
  error: null,
  loading: false,
};

export const authSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setJobCountByState: (state, { payload }) => {
      const { all, pendingAssign, completed, incoming } = payload;

      state.jobCount = {
        all,
        pendingAssign,
        completed,
        incoming,
      };
      state.loading = false;
    },
    setError: (state, { payload }) => {
      state.error = handleError(payload);
    },
  },
});

export const { setLoading, setJobCountByState, setError } = authSlice.actions;

export const selectDashboard = (state: any) => state.dashboard;

export default authSlice.reducer;
