import { TDResource } from 'helpers/resource'

const tdResource = new TDResource()
const tdApi = tdResource.getInstance()

export const getJobs = (query: any) => {
  return tdApi.get(`v1/jobs`, { params: query })
}

export const getJobById = (jobId: string) => {
  return tdApi.get(`v1/jobs/${jobId}`)
}

export const assignProvider = (jobId: string, providerId: string) => {
  return tdApi.post(`v1/jobs/${jobId}/providers/${providerId}/assign`)
}

export const wfAssignProvider = (wfJobId: string, providerId: string) => {
  const formData = new FormData()
  formData.append('providerId', providerId)

  return tdApi.post(`v1/jobs/${wfJobId}/assign_provider`, formData)
}

export const bulkAssign = (
  target: string,
  targetId: string,
  jobIds: string[]
) => {
  return tdApi.post(`v1/jobs/bulk_assign`, {
    target,
    targetId,
    jobIds,
  })
}

export const assignTeam = (jobId: string, teamId: string) => {
  return tdApi.post(`v1/jobs/${jobId}/teams/${teamId}/assign`)
}
