import { TDResource } from "helpers/resource";

const tdResource = new TDResource();
const tdApi = tdResource.getInstance();

export const getTeams = (query: any) => {
  return tdApi.get(`v1/teams`, { params: query });
};

export const getTeamById = (teamId: string) => {
  return tdApi.get(`v1/teams/${teamId}`);
};

export const createTeam = (data: any) => {
  return tdApi.post(`v1/teams`, data);
};

export const updateTeam = (teamId: string, data: any) => {
  return tdApi.put(`v1/teams/${teamId}`, data);
};

export const getTeamMembers = (teamId: string, query: any) => {
  return tdApi.get(`v1/teams/${teamId}/members`, { params: query });
};
