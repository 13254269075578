import { TDResource } from "helpers/resource";

export interface ILogin {
  identifier: string;
  password: string;
  orgAccessKey: string;
}

const tdResource = new TDResource();
const tdApi = tdResource.getInstance();

export const login = ({ identifier, password, orgAccessKey }: ILogin) => {
  return tdApi.post(`v1/auth`, { identifier, password, orgAccessKey });
};

export const authenticate = (accessToken: string) => {
  return tdApi.get(`v1/auth`, {
    headers: { authorization: `Bearer ${accessToken}` },
  });
};
