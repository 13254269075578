import PropTypes from "prop-types";

import "./Organization.scss";

interface IProps {
  orgUrl: string;
  orgName: string;
}

const Organization = ({ orgUrl, orgName }: IProps) => {
  return (
    <div className="org-box">
      <img className="org-img" src={orgUrl} alt="org" />
      <span className="org-name">{orgName}</span>
    </div>
  );
};

Organization.propTypes = {
  orgUrl: PropTypes.string,
  orgName: PropTypes.string,
};

export default Organization;
