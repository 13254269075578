import { Dispatch } from "redux";

import {
  getOrgSuccess,
  getMembersSuccess,
  setLoading,
  setError,
} from "./orgSlice";
import * as orgApi from "./orgApi";

export const getOrgById =
  (orgId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading());

      const { data: orgMember } = await orgApi.getOrgById(orgId);

      dispatch(getOrgSuccess(orgMember));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const getOrgMembers =
  (query?: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading());

      const orgId: any = localStorage.getItem("orgId");

      const { data: orgMembers } = await orgApi.getOrgMembers(orgId, {
        ...query,
      });

      dispatch(getMembersSuccess(orgMembers));
    } catch (error) {
      dispatch(setError(error));
    }
  };
