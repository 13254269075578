import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSearchbar,
  IonText,
  IonTitle,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box'

import { DateTimeFormatter } from 'components/formatters'
import { getOrgMembers } from 'redux/org/orgAction'
import { selectOrgMembers } from 'redux/org/orgSlice'
import { getTeams } from 'pages/Team/redux/teamAction'
import { selectTeams } from 'pages/Team/redux/teamSlice'
import badgeIcon from 'assets/icons/badge.png'

import {
  assignProvider,
  assignTeam,
  bulkAssign,
} from 'pages/Job/redux/jobAction'

import './JobAssignModal.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  jobId?: any
  jobIds?: any
  wfJobId?: any
  assignType: string
  isMultiAssign?: boolean
  isOpen: boolean
  onCloseCallback: any
}

const JobAssignModal = ({
  jobId,
  jobIds,
  wfJobId,
  assignType,
  isMultiAssign = false,
  isOpen,
  onCloseCallback,
}: IProps) => {
  const { t } = useTranslation(['common', 'job', 'placeholder', 'table'])
  const [searchValue, setSearchValue] = useState('')
  const [showConfirmAssignment, setShowConfirmAssignment] = useState(false)
  const [assignTarget, setAssignTarget] = useState({
    id: '',
    name: '',
  })
  const dispatch = useDispatch()
  const {
    loading: memberLoading,
    members,
    totalPage: totalOrgMembersPage,
  } = useSelector(selectOrgMembers)
  const {
    loading: teamLoading,
    teams,
    totalPage: totalTeamPage,
  } = useSelector(selectTeams)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (assignType === 'provider') {
      dispatch(getOrgMembers({ search: searchValue, page, role: 'provider' }))
    } else {
      dispatch(getTeams({ search: searchValue, page }))
    }
  }, [searchValue, page, assignType])

  const renderMemberList = () => {
    if (assignType === 'provider') {
      return memberLoading ? <>Loading...</> : renderMember(members)
    } else {
      return teamLoading ? <>Loading...</> : renderTeam(teams)
    }
  }

  const assignRequest = () => {
    if (isMultiAssign) {
      if (assignType === 'provider') {
        dispatch(bulkAssign('provider', assignTarget.id, jobIds))
      } else {
        dispatch(bulkAssign('team', assignTarget.id, jobIds))
      }
    } else {
      if (assignType === 'provider') {
        dispatch(assignProvider(jobId, assignTarget.id))
      } else {
        dispatch(assignTeam(jobId, assignTarget.id))
      }
    }
  }

  const renderMember = (members: any) => {
    return (
      <IonGrid style={{ minWidth: '992px' }}>
        <IonRow className="member-list-header">
          <IonCol className="name">
            <IonText color="primary">
              <strong>{t('provider-name')}</strong>
            </IonText>
          </IonCol>
          <IonCol>
            <IonText color="primary">
              <strong>{t('team')}</strong>
            </IonText>
          </IonCol>
          <IonCol className="phone-number">
            <IonText color="primary">
              <strong>{t('contact')}</strong>
            </IonText>
          </IonCol>
          <IonCol className="email">
            <IonText color="primary">
              <strong>{t('email')}</strong>
            </IonText>
          </IonCol>
          <IonCol className="rating">
            <IonText color="primary">
              <strong>{t('rating')}</strong>
            </IonText>
          </IonCol>
          <IonCol>
            <IonText color="primary">
              <strong>{t('joined-since')}</strong>
            </IonText>
          </IonCol>
          <IonCol>
            <IonText color="primary">
              <strong>{t('assign')}</strong>
            </IonText>
          </IonCol>
        </IonRow>
        {members.map((member: any) => (
          <IonRow key={member._id} className="render-member">
            <IonCol className="name">
              <IonItem lines="none">
                <IonAvatar slot="start" class="avatar-provider">
                  <img
                    alt="preview "
                    src={
                      member.avatarUrl ||
                      'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                    }
                  ></img>
                </IonAvatar>
                <IonLabel className="ion-no-margin">{member.fullName}</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol>
              {member.teams
                ? `${t('joined-team')} ${member.teams
                    .map((member: any) => member.name.trim())
                    .join(', ')}`
                : ''}
            </IonCol>
            <IonCol className="phone-number">{member.phoneNumber}</IonCol>
            <IonCol className="email">{member.email}</IonCol>
            <IonCol className="rating">{`${t('rating').toLocaleLowerCase()} ${
              member.rating
            }/5`}</IonCol>
            <IonCol>
              <DateTimeFormatter value={member.createdAt} />
            </IonCol>
            <IonCol>
              <IonButton
                onClick={() => {
                  setAssignTarget({
                    id: member._id,
                    name: member.fullName,
                  })
                  setShowConfirmAssignment(true)
                }}
              >
                {' '}
                {t('btn.assign-job')}{' '}
              </IonButton>
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
    )
  }

  const renderTeam = (teams: any) => {
    return (
      <IonGrid style={{ minWidth: '992px' }}>
        <IonRow className="member-list-header">
          <IonCol size="2" className="name">
            <IonText color="primary">
              <strong>{t('team-name')}</strong>
            </IonText>
          </IonCol>
          <IonCol>
            <IonText color="primary">
              <strong>{t('team')}</strong>
            </IonText>
          </IonCol>
          <IonCol className="phone-number">
            <IonText color="primary">
              <strong>{t('contact')}</strong>
            </IonText>
          </IonCol>
          <IonCol className="email">
            <IonText color="primary">
              <strong>{t('email')}</strong>
            </IonText>
          </IonCol>
          <IonCol className="member">
            <IonText color="primary">
              <strong>{t('member')}</strong>
            </IonText>
          </IonCol>
          <IonCol>
            <IonText color="primary">
              <strong>{t('joined-since')}</strong>
            </IonText>
          </IonCol>
          <IonCol>
            <IonText color="primary">
              <strong>{t('assign')}</strong>
            </IonText>
          </IonCol>
        </IonRow>
        {teams.map((team: any) => (
          <IonRow key={team._id} className="render-team">
            <IonCol size="2" className="name">
              <IonItem lines="none">
                <IonAvatar slot="start" class="avatar-provider">
                  <img
                    alt="preview "
                    src={
                      team.pictureUrl ||
                      'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                    }
                  ></img>
                </IonAvatar>
                <IonLabel>{team.name}</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol>
              {team.leader?.fullName}{' '}
              <img
                alt="preview"
                className="teams-list-team-lead-elems "
                src={badgeIcon}
              />
            </IonCol>
            <IonCol className="phone-number">{team.leader?.phoneNumber}</IonCol>
            <IonCol className="email">{team.leader?.email}</IonCol>
            <IonCol className="member">{`${team.members.length} ${t(
              'member-unit'
            )}`}</IonCol>
            <IonCol>
              <DateTimeFormatter value={team.createdAt} />
            </IonCol>
            <IonCol>
              <IonButton
                color="primary"
                onClick={() => {
                  setAssignTarget({
                    id: team._id,
                    name: team.name,
                  })
                  setShowConfirmAssignment(true)
                }}
              >
                {' '}
                {t('btn.assign-job')}{' '}
              </IonButton>
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
    )
  }

  return (
    <IonModal
      id="job-assign-modal"
      isOpen={isOpen}
      onDidDismiss={() => onCloseCallback()}
    >
      <IonContent>
        <IonGrid class="job-info-header-conten-modal">
          <IonRow>
            <IonButton
              className="close-icon-button"
              onClick={() => onCloseCallback()}
            >
              <IonIcon icon={closeOutline}></IonIcon>
            </IonButton>
          </IonRow>
          <IonRow class="header-content-modal">
            <IonTitle>{t('assign-job-modal-title')}</IonTitle>
          </IonRow>
          <IonRow class="header-sub-content-modal">
            <IonLabel>{t('assign-job-modal-sub-title')}</IonLabel>
          </IonRow>
        </IonGrid>
        <IonGrid class="modal-contanier">
          <IonRow class="search-container">
            <IonSearchbar
              class="search-field"
              placeholder={t('searchbar-name-and-phonenumber-placeholder')}
              value={searchValue}
              onIonChange={(e: any) => setSearchValue(e.target.value)}
            ></IonSearchbar>
          </IonRow>
          <IonRow>
            <IonCol style={{ overflowX: 'scroll' }}>
              {renderMemberList()}
            </IonCol>
          </IonRow>
          <Box height="100%" display="flex" justifyContent="center">
            <Pagination
              count={
                assignType === 'provider' ? totalOrgMembersPage : totalTeamPage
              }
              page={page}
              color="primary"
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value)
              }}
            />
          </Box>
        </IonGrid>
      </IonContent>
      <IonAlert
        isOpen={showConfirmAssignment}
        onDidDismiss={() => setShowConfirmAssignment(false)}
        header={`${t('message.assign-job-to-header')} "${assignTarget.name}"`}
        message={t('message.assign-job-to-desc')}
        buttons={[
          {
            text: t('cancel'),
            handler: () => {
              setShowConfirmAssignment(false)
            },
          },
          {
            text: t('ok'),
            handler: () => {
              assignRequest()
            },
          },
        ]}
      />
    </IonModal>
  )
}

export default JobAssignModal
