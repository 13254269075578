class configs {
  static path: any = 'https://api-team-dist.workforce-staging.com/v1'
  static sign_in = 'https://api.workforce-develop.com/sign_in'
  static sign_in_username = 'b27ee5f0bd8637a0cc9027137240ff14'
  static sign_in_password =
    'n89hr9UNNrCoIM21SWN7uQidqxl9NZ+KgMUeXuhqYFQ8f//HtSuQowh7HwGOYE4DjOT8LUpsW14Pn0W4kbvIwQ=='
  static bearer_token =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MmExYmI5Y2Q5NmMxZmZiNDY2ZWE0NTAiLCJhY2Nlc3NUb2tlbiI6IkJ5M21RL0tIZnUyeEdJa3dpdXNJNlZqMy95QmYrMUROV2lpLzFRdlgvS2JuRVVqV0pRODJjdWdUeE5JdkFYVDdDS0h3TFBSTE1RTWFnWEhBc25kR3N3PT0iLCJpYXQiOjE2NTQ3NjY3ODcsImV4cCI6MTk3MDEyNjc4N30.aiHtUots5lGZ6KygiBXl8JntKjPwf4iCUyRPqV32Eb0'
  static providers_endpoint = 'https://api.workforce-develop.com/providers'
  static jobs_endpoint = 'https://api.workforce-develop.com/jobs'
  static reset_username = '7dbb9fdea82fcc4694ee6da198334538'
  static reset_password =
    'KPAPd1djz4L/KnjmcHj76/oz59trM8Gj2XelnEAxHtlpxNhrpfPBeYhU7XKkimaStmpF+Mj0LoTp13WEV/Z8PA=='
  static reset_endpoint = 'https://api.workforce-develop.com/reset_instructions'
  static user: any
  static orgId: any

  static setUser(user: number) {
    configs.user = user
  }
  static setOrgId(orgId: string) {
    configs.orgId = orgId
  }
}

export default configs
