import { createSlice } from "@reduxjs/toolkit";
import { IBaseState } from "redux/BaseState";
import { handleError } from "helpers/handleError";

export interface IOrgState extends IBaseState {
  org: {
    _id: string | null;
    name: string | null;
    email: string | null;
    information: string | null;
    phoneNumber: string | null;
    address: string | null;
    isRequireAdminApprove: boolean | null;
    isRequireOrgApprove: boolean | null;
    accessKey: string | null;
    number: number | null;
    pictureUrl: string | null;
  };
  members: [];
}

const initialState: IOrgState = {
  org: {
    _id: null,
    name: null,
    email: null,
    information: null,
    phoneNumber: null,
    address: null,
    isRequireAdminApprove: false,
    isRequireOrgApprove: false,
    accessKey: null,
    number: null,
    pictureUrl: null,
  },
  members: [],
  error: null,
  loading: false,
};

export const authSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getOrgSuccess: (state, { payload }) => {
      state.org = payload;
      state.loading = false;
      state.error = null;
    },
    getMembersSuccess: (state, { payload }) => {
      state.members = payload;
      state.loading = false;
      state.error = null;
    },

    setError: (state, { payload }) => {
      state.error = handleError(payload);
      state.loading = false;
    },
  },
});

export const { getOrgSuccess, getMembersSuccess, setLoading, setError } =
  authSlice.actions;

export const selectOrg = (state: any) => state.org;

export const selectOrgMembers = ({ org }: any) => ({
  members: org.members,
  totalPage: Math.ceil(org.members.length / 30) || 0,
  loading: false,
});

export default authSlice.reducer;
