import { Dispatch } from "redux";

import {
  IUser,
  ITeam,
  loggedIn,
  loggedOut,
  setLoading,
  setError,
} from "./authSlice";
import { getOrgById } from "redux/org/orgAction";
import {
  ILogin,
  login as loginApi,
  authenticate as authenticateApi,
} from "./authApi";

const { REACT_APP_WF_AGENT_ACCESS_TOKEN } = process.env;

const serialzeUser = (user: IUser) => {
  return {
    _id: user._id,
    avatarUrl: user.avatarUrl || "/assets/icon/default-avatar.svg",
    username: user.username,
    email: user.email || "No email",
    wfUserId: user.wfUserId,
    fullName: user.fullName,
    phoneNumber: user.phoneNumber,
    rating: user.rating,
    isActived: user.isActived,
    wfAccessToken: user.wfAccessToken,
    org: {
      _id: user.org._id,
      name: user.org.name,
      email: user.org.email,
      information: user.org.information,
      phoneNumber: user.org.phoneNumber,
      address: user.org.address,
      isRequireAdminApprove: user.org.isRequireAdminApprove,
      isRequireOrgApprove: user.org.isRequireOrgApprove,
      accessKey: user.org.accessKey,
      number: user.org.number,
      pictureUrl: user.org.pictureUrl,
    },
    teams: user.teams.map((team: ITeam) => ({
      _id: team._id,
      name: team.name,
      description: team.description,
      pictureUrl: team.pictureUrl,
    })),
  };
};

export const login =
  ({ identifier, password, orgAccessKey }: ILogin): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading());

      const { data } = await loginApi({ identifier, password, orgAccessKey });
      const { user, token } = data;

      localStorage.setItem("accessToken", token);
      localStorage.setItem(
        "WFAgentAccessToken",
        `${REACT_APP_WF_AGENT_ACCESS_TOKEN}`
      );
      localStorage.setItem("WFAccessToken", `${user.wfAccessToken}`);
      localStorage.setItem("orgId", user.org._id);

      window.location.href = "/";
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const logout = (): any => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading());

    localStorage.removeItem("accessToken");
    localStorage.removeItem("WFAccessToken");
    localStorage.removeItem("WFAgentAccessToken");
    localStorage.removeItem("orgId");

    dispatch(loggedOut());
  } catch (error) {
    dispatch(setError(error));
  }
};

export const authenticate = (): any => async (dispatch: Dispatch) => {
  try {
    dispatch(setLoading());

    const accessToken: any = localStorage.getItem("accessToken");
    const orgId: any = localStorage.getItem("orgId");

    const { data: user } = await authenticateApi(accessToken);

    dispatch(loggedIn({ user: serialzeUser(user), accessToken }));
    dispatch(getOrgById(orgId));
  } catch (error) {
    dispatch(setError(error));
  }
};
