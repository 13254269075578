import { TeamForm } from "../Team/Form";
import "./NewTeam.scss";

const NewTeam = () => {
  return (
    <div className="tab-create-teams">
      <TeamForm />
    </div>
  );
};

export default NewTeam;
