import { Dispatch } from 'redux'

import {
  getJobsSuccess,
  getJobSuccess,
  getWFJobSuccess,
  commentJobSuccess,
  assignJobToProviderSuccess,
  assignJobToTeamSuccess,
  bulkAssignJobSuccess,
  setLoading,
  setError,
} from './jobSlice'
import { show as showAlert } from 'redux/alert/alertSlice'

import * as jobApi from './jobApi'
import * as wfJobApi from './wfJobApi'
import i18n from 'i18n'

export const getJobs =
  (query: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      const { data: jobs, headers } = await jobApi.getJobs(query)

      dispatch(
        getJobsSuccess({
          items: jobs,
          total: headers.total,
          pageSize: headers['per-page'],
        })
      )

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const getJobById =
  (jobId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      const { data: job } = await jobApi.getJobById(jobId)
      const [{ data: wfJob }, { data: comments }, { data: jobProviders }] =
        await Promise.all([
          wfJobApi.getJobById(job.wfJobId),
          wfJobApi.getJobCommentsByJobId(job.wfJobId),
          wfJobApi.getJobProvidersByJobId(job.wfJobId),
        ])

      const jobProvider =
        jobProviders.find((p: any) => p.state === 'completed') ||
        jobProviders?.[0]
      let jobProviderRes
      if (jobProvider) {
        jobProviderRes = await wfJobApi.getJobProviderByJobProviderId(
          jobProvider?.id
        )
      }

      dispatch(getJobSuccess(job))
      dispatch(
        getWFJobSuccess({
          job: wfJob,
          comments,
          jobProvider:
            jobProviders.length > 0
              ? jobProviders.find((jp: any) => jp.state === 'completed')
              : {},
          reportQuestions: jobProviderRes?.data?.report_questions || [],
          reportAnswers: jobProviderRes?.data?.answers || [],
        })
      )

      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const commentJob =
  (jobId: number, message: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      await wfJobApi.comment(jobId, message)

      dispatch(commentJobSuccess())
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const assignProvider =
  (jobId: string, providerId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      await jobApi.assignProvider(jobId, providerId)

      dispatch(assignJobToProviderSuccess())
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.assign-job-success'),
          redirect: `/jobs/${jobId}`,
        })
      )
    } catch (error: any) {
      dispatch(setError(error))
      dispatch(
        showAlert({
          type: 'error',
          message: error?.response?.data?.message || error?.message,
        })
      )
    }
  }

export const assignTeam =
  (jobId: string, teamId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      await jobApi.assignTeam(jobId, teamId)

      dispatch(assignJobToTeamSuccess())
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.assign-job-success'),
          redirect: `/jobs/${jobId}`,
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const bulkAssign =
  (target: string, targetId: string, jobIds: string[]): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      await jobApi.bulkAssign(target, targetId, jobIds)

      dispatch(bulkAssignJobSuccess())
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.assign-job-success'),
          redirect: '/jobs',
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const updateJobProvider =
  (jobProviderId: number, data: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      await wfJobApi.updateJobProvider(jobProviderId, data)

      dispatch(setLoading(false))
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.edit-job-success'),
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }
