import { useParams } from 'react-router'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonText,
} from '@ionic/react'
import { chevronBack } from 'ionicons/icons'

import { TeamForm } from '../Form'
import { useTranslation } from 'react-i18next'

interface IParams {
  teamId: string
}

const EditTeam = () => {
  const { t } = useTranslation('common')
  const { teamId } = useParams<IParams>()

  return (
    <IonPage>
      <IonContent>
        <IonGrid class="teams-header-content">
          <IonRow>
            <IonCol>
              <IonRouterLink href={`/teams/teams/${teamId}`}>
                <div className="teams-info-back-button display-flex">
                  <IonIcon
                    icon={chevronBack}
                    class="align-self-center icon-24"
                  />
                  <IonText class="align-self-center">{t('btn.back')}</IonText>
                </div>
              </IonRouterLink>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel class="team-page-label">
                <IonText>{t('title.edit-team')}</IonText>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>{t('title.edit-team-description')}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <TeamForm teamId={teamId} />
      </IonContent>
    </IonPage>
  )
}

export default EditTeam
