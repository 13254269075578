import PropTypes from 'prop-types'
import { IonContent, IonModal } from '@ionic/react'
import { OrgMemberList } from '../OrgMemberList'

import './TeamLeadModal.scss'

interface IProps {
  leaderId: string
  isOpen: boolean
  onToggle: any
  onChange: any
}

const TeamLeadModal = ({ leaderId, isOpen, onToggle, onChange }: IProps) => {
  return (
    <IonModal
      id="org-member-modal"
      isOpen={isOpen}
      onDidDismiss={() => onToggle(false)}
    >
      <IonContent className="org-member-content" scrollY>
        <OrgMemberList
          options={{
            selectionMode: 'single',
          }}
          selected={leaderId}
          onChange={onChange}
          onToggle={onToggle}
        />
      </IonContent>
    </IonModal>
  )
}

TeamLeadModal.propTypes = {
  leaderId: PropTypes.string,
  isOpen: PropTypes.bool,
  ontoggle: PropTypes.func,
  onChange: PropTypes.func,
}

export default TeamLeadModal
