import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonSelectOption,
  IonSelect,
  IonText,
} from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { getJobs } from './redux/jobAction'
import { selectJobPagiante } from './redux/jobSlice'
import StateTag from 'components/StateTag'
import { Datatable } from 'components/Datatable'
import { DateTimeFormatter } from 'components/formatters'
import JobAssignModal from './JobSubPages/JobAssignModal'
import ReadMore from 'components/ReadMore'

import './JobPage.scss'

const JobPage = () => {
  const [assignTeamOrProvider, setAssignTeamOrProvider] = useState<string>('')
  const [wfJobId, setWFJobId] = useState<string>('')
  const [showAssignJobModal, setShowAssignJobModal] = useState<boolean>(false)
  const [assignJobId, setAssignJobId] = useState<any>(null)
  const { loading, items, totalPage } = useSelector(selectJobPagiante)
  const [placeholderValue] = useState(null)
  const [selectedJobIds, setSelectedJobIds] = useState([])
  const [isMultiAssign, setIsMultiAssign] = useState(false)

  const { t, i18n } = useTranslation(['job', 'table', 'common'])

  const columns = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: t('job'),
    },
    {
      id: 'customer',
      numeric: false,
      disablePadding: false,
      label: t('customer'),
    },
    {
      id: 'assignedAt',
      numeric: false,
      disablePadding: false,
      label: t('table:assigned-date'),
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: t('order'),
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: t('address'),
    },
    {
      id: 'startTime',
      numeric: false,
      disablePadding: false,
      label: t('start-date'),
    },
    {
      id: 'payout',
      numeric: false,
      disablePadding: false,
      label: t('payout'),
    },
    {
      id: 'comment',
      numeric: false,
      disablePadding: false,
      label: t('comment'),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('status'),
    },
    {
      id: 'assign',
      numeric: false,
      disablePadding: false,
      label: t('assign'),
    },
  ]

  const assignJobClicked = (
    e: any,
    jobId?: string,
    isMulti: boolean = true,
    wfJobId?: string
  ) => {
    if (jobId) setAssignJobId(jobId)
    setIsMultiAssign(isMulti)
    if (wfJobId) setWFJobId(wfJobId)

    if (e.detail.value === 'team' || e.detail.value === 'provider') {
      setAssignTeamOrProvider(e.detail.value)
    } else if (e.detail.value !== null && assignTeamOrProvider !== '') {
      setAssignTeamOrProvider(assignTeamOrProvider)
    }

    setShowAssignJobModal(true)
  }

  const transformData = (items: any) => {
    return items.map((item: any) => {
      const lang = i18n.language.toUpperCase()
      const langName = `name${lang}`
      const orders = item.orders.map(
        (order: any) => `${order[langName]} x ${order.qty}`
      )

      return {
        _id: item._id,
        data: item,
        number: <Link to={`/jobs/${item._id}`}>{item.number}</Link>,
        customer: item.customer.fullName,
        assignedAt: <DateTimeFormatter value={item.createdAt} />,
        description: <ReadMore list={orders} />,
        address: item.address,
        startTime: <DateTimeFormatter value={item.startTime} />,
        payout: item.payout,
        comment: (
          <Link to={`/jobs/${item._id}?section=comments`}>
            <img
              src="https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/feather-edit.svg"
              alt="comment icon"
            />
          </Link>
        ),
        status: (
          <StateTag
            value={
              item.team && item.status === 'pending_assign'
                ? 'assign_team'
                : item.status
            }
            round={false}
          />
        ),
        action: (
          <IonItem className="assign-job-button">
            <IonLabel>{t('btn.assign-job')}</IonLabel>
            <IonSelect
              disabled={item.status !== 'pending_assign' || item.team}
              interface="popover"
              class="assign-job-dropdown"
              selectedText=""
              value={placeholderValue}
              onIonChange={(e) =>
                assignJobClicked(e, item._id, false, item.wfJobId)
              }
            >
              <IonSelectOption value="team" class="select-option-no-radio">
                {t('team')}
              </IonSelectOption>
              <IonSelectOption value="provider" class="select-option-no-radio">
                {t('provider')}
              </IonSelectOption>
            </IonSelect>
          </IonItem>
        ),
      }
    })
  }

  return (
    <>
      <IonGrid class="job-header-content">
        <IonRow>
          <IonCol>
            <IonLabel class="job-page-label">
              <IonText>{t('job-page-title')}</IonText>
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText>{t('job-page-sub-title')}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid class="job-page-content">
        {loading ? (
          <>Loading...</>
        ) : (
          <Datatable
            resource={getJobs}
            columns={columns}
            data={transformData(items)}
            totalPage={totalPage}
            onSelected={(ids: any) => setSelectedJobIds(ids)}
            toolbar={
              <IonItem className="assign-job-button">
                <IonLabel>{t('assign')}</IonLabel>
                <IonSelect
                  interface="popover"
                  class="assign-job-dropdown"
                  selectedText=""
                  value={placeholderValue}
                  onIonChange={(e) => assignJobClicked(e)}
                >
                  <IonSelectOption value="team" class="select-option-no-radio">
                    {t('team')}
                  </IonSelectOption>
                  <IonSelectOption
                    value="provider"
                    class="select-option-no-radio"
                  >
                    {t('provider')}
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
            }
          />
        )}

        {showAssignJobModal ? (
          <JobAssignModal
            jobIds={selectedJobIds}
            jobId={assignJobId}
            wfJobId={wfJobId}
            isMultiAssign={isMultiAssign}
            isOpen={showAssignJobModal}
            onCloseCallback={() => setShowAssignJobModal(false)}
            assignType={assignTeamOrProvider}
          />
        ) : null}
      </IonGrid>
    </>
  )
}

export default JobPage
