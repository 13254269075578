import {
  IonAvatar,
  IonCol,
  IonIcon,
  IonImg,
  IonLabel,
  IonRow,
} from '@ionic/react'
import { people } from 'ionicons/icons'
import badgeIcon from 'assets/icons/badge.png'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

interface IProps {
  team: string
  members: []
  leader?: any
}

const MemberList = ({ team, members, leader }: IProps) => {
  const { t } = useTranslation('common')
  return (
    <>
      {members.map(({ member, joinedAt }: any) => (
        <IonRow className="table-row" key={member._id}>
          <IonCol size="2">
            <IonAvatar className="team-image-in-list">
              <img
                alt="preview"
                src={
                  member.avatarUrl ||
                  'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                }
              ></img>
            </IonAvatar>
            <IonLabel className="margin-left-4">{member.fullName}</IonLabel>
          </IonCol>
          <IonCol size="1" className="display-flex">
            <IonLabel className="align-self-center">
              {member?._id === leader?._id ? (
                <div className="member-role">
                  <IonImg className="badge-icon" src={badgeIcon} />{' '}
                  {t('leader')}
                </div>
              ) : (
                <div className="member-role">
                  <IonIcon
                    className="member-icon"
                    icon={people}
                    color="primary"
                  />{' '}
                  {t('member').toLowerCase()}
                </div>
              )}
            </IonLabel>
          </IonCol>
          <IonCol size="1" className="display-flex">
            <IonLabel className="align-self-center">
              {member.phoneNumber}
            </IonLabel>
          </IonCol>
          <IonCol size="2" className="display-flex">
            <IonLabel className="align-self-center">{member.email}</IonLabel>
          </IonCol>
          <IonCol size="2" className="display-flex">
            <IonLabel className="align-self-center">{team}</IonLabel>
          </IonCol>
          <IonCol size="1" className="display-flex">
            <IonLabel className="align-self-center">
              {`เรทติ้ง ${member.rating}/5`}
            </IonLabel>
          </IonCol>
          <IonCol size="2" className="display-flex">
            <IonLabel className="align-self-center">
              {`${new Date(joinedAt).toLocaleDateString('th-TH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}`}
            </IonLabel>
          </IonCol>
        </IonRow>
      ))}
    </>
  )
}

MemberList.propTypes = {
  team: PropTypes.string,
  members: PropTypes.array,
}

export default MemberList
