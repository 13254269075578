import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  IonAvatar,
  IonTextarea,
  IonImg,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
} from '@ionic/react'
import PropTypes from 'prop-types'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

import StateTag from 'components/StateTag'
import QuestionsAndAnswers from 'components/QuestionsAndAnswers'
import CommentCard from 'components/CommentCard'
import { commentJob } from 'pages/Job/redux/jobAction'
import { approve } from '../redux/reportAction'

import './ReportDetail.scss'
import { createOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'

interface IProps {
  report: any
  job: any
  wfJob: any
  loading: boolean
}

const OrderList = ({ orders }: any) => {
  return orders.map((order: any, key: number) => (
    <IonRow key={key} class="order-row">
      <IonCol size="10">{order.package.name}</IonCol>
      <IonCol size="2">x{order.quantity}</IonCol>
    </IonRow>
  ))
}

const Details = (props: IProps) => {
  const { report, job, wfJob, loading } = props
  const { t } = useTranslation(['report', 'common'])
  const [commentMessage, setCommentMessage] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  const formatTime = (time: string) => moment(time).format('HH:mm')

  const postComment = () => {
    dispatch(commentJob(wfJob.job.id, commentMessage))
  }

  const approveReport = () => {
    dispatch(approve(report._id))
  }

  const handleEdit = () => {
    history.push(`${history.location.pathname}/edit`)
  }

  const sortedAnswers = () => {
    return sortBy(wfJob.jobProvider.answers, 'id')
  }

  return (
    <IonGrid class="job-info-content-grid">
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <IonRow class="margin-top-8">
            <IonCol>
              <IonRow class="margin-top-8">
                <IonGrid>
                  <IonRow>
                    <IonCol size="2">
                      <IonRow className="row-flex-end">
                        <IonAvatar>
                          <IonImg
                            className="service-icon"
                            src={
                              job.team
                                ? job.team.pictureUrl
                                : 'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/Org-icon.svg'
                            }
                            alt="Service Icon"
                          />
                        </IonAvatar>
                      </IonRow>
                    </IonCol>
                    <IonCol size="2">
                      <IonRow className="row-flex-end">
                        <IonText className="name-title">
                          {job.team ? job.team.name : 'No team'}
                        </IonText>
                      </IonRow>
                      <IonRow>
                        <IonText className="time-comment">
                          {t('tel')} {job.team ? job.team.phoneNumber : '-'}
                        </IonText>
                      </IonRow>
                      <IonRow>
                        <IonText>
                          {job.team ? job.team.leader?.fullName : '-'}
                        </IonText>
                      </IonRow>
                    </IonCol>
                    <IonCol size="4">
                      <StateTag value={report.status} round={true} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonRow>
            </IonCol>
            <IonCol className="edit-column ion-justify-content-end ion-align-items-start">
              {(report.status === 'pending_approval' ||
                report.status === 'rejected') && (
                <IonItem lines="none" onClick={handleEdit}>
                  <IonIcon slot="start" icon={createOutline} />
                  {t('edit-report')}
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-top-8">
            <IonCol>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('orders')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonLabel>
                  <OrderList orders={wfJob.job.orders} />
                </IonLabel>
              </IonRow>
            </IonCol>
            <IonCol>
              <IonLabel class="float-right">
                <div>
                  <IonLabel class="date-text-number">
                    {moment(wfJob.job.startTime).format('D')}
                  </IonLabel>

                  <IonLabel class="date-time-text-number">
                    {moment(wfJob.job.startTime).format('MMMM YYYY')}{' '}
                  </IonLabel>
                </div>

                <div className="time-ragne-container">
                  <IonLabel>
                    {t('service-duration')}{' '}
                    {moment(wfJob.job.end_time).diff(
                      moment(wfJob.job.start_time),
                      'hours'
                    )}{' '}
                    {t('hour-unit')}
                  </IonLabel>
                </div>
                <div className="time-container">
                  <IonLabel class="start-time-number">
                    {`${t('job-start-time')} ${formatTime(
                      wfJob.job.start_time
                    )}`}
                  </IonLabel>
                  <IonLabel>{`${t('finish-time')} ${formatTime(
                    wfJob.job.end_time
                  )}`}</IonLabel>
                </div>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow class="margin-top-8">
            <IonGrid>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('contact-detail')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonText>{wfJob.job.customer.full_name}</IonText>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonText>
                  {t('tel')} {wfJob.job.customer.phone_number || '-'}
                </IonText>
              </IonRow>
              <IonRow class="margin-top-8">
                <IonText> {wfJob.job.location.full_address || '-'}</IonText>
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="margin-top-8">
            <IonGrid>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('title.closing-job-question')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                <QuestionsAndAnswers answers={sortedAnswers()} />
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="margin-top-16">
            <IonGrid>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('customer-signature')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                {wfJob.jobProvider.signature &&
                wfJob.jobProvider.signature.file_url ? (
                  <img
                    style={{ height: 200, width: 200 }}
                    src={wfJob.jobProvider.signature.file_url}
                    alt="answerImage"
                  />
                ) : (
                  <IonText>-</IonText>
                )}
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="margin-top-16">
            <IonCol>
              <IonRow>
                <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                  {t('comment')}
                </IonLabel>
              </IonRow>
              <IonRow class="margin-top-8">
                {wfJob.comments.length === 0 ? (
                  <IonTextarea
                    rows={7}
                    cols={20}
                    placeholder={t('comment-description')}
                    value={commentMessage}
                    class="text-area-comment"
                    onIonChange={(e) => setCommentMessage(e.detail.value!)}
                  ></IonTextarea>
                ) : (
                  <CommentCard value={wfJob.comments[0]} />
                )}
              </IonRow>
              <IonButton
                onClick={() => {
                  if (wfJob.comments.length === 0) {
                    postComment()
                  } else {
                    history.push(`/jobs/${job._id}?section=comments`)
                  }
                }}
              >
                <IonText>{t('btn.comment')}</IonText>
              </IonButton>
            </IonCol>
            <IonCol />
            {report.status === 'pending_approval' && (
              <IonCol>
                <IonButton class="approve-btn" onClick={approveReport}>
                  <IonText>{t('btn.approve-job')}</IonText>
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </>
      )}
    </IonGrid>
  )
}

Details.propTypes = {
  job: PropTypes.object,
  wfJob: PropTypes.object,
  loading: PropTypes.bool,
}

export default Details
