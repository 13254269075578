import { IonCol, IonGrid, IonLabel, IonRow, IonAvatar } from '@ionic/react'
import PropTypes from 'prop-types'

import StateTag from 'components/StateTag'
import { useTranslation } from 'react-i18next'

interface IProps {
  job: any
}

const JobProvider = ({ job }: IProps) => {
  const { t } = useTranslation('common')

  return (
    <IonGrid class="job-info-content-grid">
      <IonRow class="margin-top-16">
        <IonGrid>
          <IonRow>
            <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
              {t('provider')}
            </IonLabel>
          </IonRow>

          {job.status === 'pending_assign' ? (
            <>{t('no-provider')}</>
          ) : (
            <IonRow>
              <IonGrid>
                <IonRow class="provider-row">
                  <IonCol>
                    <IonAvatar class="avatar-provider">
                      <img
                        alt="preview"
                        src={
                          job.provider?.avatarUrl ||
                          'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                        }
                      ></img>
                    </IonAvatar>
                    <IonCol>
                      <IonLabel>{job.provider?.fullName}</IonLabel>
                    </IonCol>
                  </IonCol>
                  <IonCol>
                    <IonLabel>{job.provider?.phoneNumber}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>{job.provider?.email}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>
                      {job.provider?.teams
                        .map((team: any) => team.name)
                        .join(',')}
                    </IonLabel>
                  </IonCol>
                  <IonCol>
                    <IonLabel>
                      {t('rating')} {job.provider?.rating}/5
                    </IonLabel>
                  </IonCol>
                  <IonCol class="state-tag-provider">
                    <StateTag value={job.status} round={false} right />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonRow>
          )}
        </IonGrid>
      </IonRow>
    </IonGrid>
  )
}

JobProvider.propTypes = {
  job: PropTypes.any,
}

export default JobProvider
