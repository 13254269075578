import { Component } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText } from '@ionic/react';
import './DashboardCard.css';

interface DashboardCardProps {
    description?: string;
    number?: Number;
    unit?: string;
    color?: string;
    borderColor?: string;
}

interface DashboardCardState {

}

class DashboardCard extends Component<DashboardCardProps, DashboardCardState> {
    render() {
        return (
            <IonCard color={this.props.color}>
                <IonCardHeader>
                    <IonCardSubtitle>
                        <IonText color="white">{this.props.description}</IonText>
                    </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonCardTitle>
                        <IonText class="number-text" color="white">{this.props.number}</IonText>
                    </IonCardTitle>
                    <IonCardSubtitle class="unit-text">
                        <IonText color="white">{this.props.unit}</IonText>
                    </IonCardSubtitle>
                </IonCardContent>
            </IonCard>
        )
    }
}

export default DashboardCard