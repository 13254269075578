import { WFResource } from 'helpers/resource'
import { BaseResource } from 'helpers/resource/BaseResource'

const wfResource = new WFResource()
const wfApi = wfResource.getInstance()

export const getJobById = (jobId: number) => {
  return wfApi.get(
    `/jobs/${jobId}?locale=${localStorage.getItem('i18nextLng')}`
  )
}

export const getJobProvidersByJobId = (jobId: number) => {
  return wfApi.get(
    `/jobs/${jobId}/job_providers?locale=${localStorage.getItem('i18nextLng')}`
  )
}

export const getJobProviderByJobProviderId = (jobProviderId: number) => {
  return wfApi.get(
    `/job_providers/${jobProviderId}?locale=${localStorage.getItem(
      'i18nextLng'
    )}`
  )
}

export const updateJobProvider = (jobProviderId: number, data: any) => {
  return wfApi.put(`/job_providers/${jobProviderId}`, data)
}

export const getJobCommentsByJobId = (jobId: number) => {
  return wfApi.get(`/jobs/${jobId}/comments`)
}

export const comment = (jobId: number, comment: string) => {
  const { REACT_APP_WF_BASE_API }: any = process.env
  const accessToken: any = localStorage.getItem('WFAccessToken')

  const baseResource = new BaseResource(accessToken, REACT_APP_WF_BASE_API)
  const individualApi = baseResource.getInstance()

  return individualApi.post(`/jobs/${jobId}/comments`, {
    content: comment,
  })
}
