import PropTypes from 'prop-types'
import { IonButton, IonContent, IonModal, IonText } from '@ionic/react'

import { OrgMemberList } from '../OrgMemberList'

import './OrgMemberModal.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  memberIds: string[]
  isOpen: boolean
  onToggle: any
  onChange: any
}

const OrgMemberModal = ({
  memberIds = [],
  isOpen,
  onToggle,
  onChange,
}: IProps) => {
  const { t } = useTranslation(['team'])

  return (
    <IonModal
      id="org-member-modal"
      isOpen={isOpen}
      onDidDismiss={() => onToggle(false)}
      swipeToClose
    >
      <IonContent className="org-member-content">
        <OrgMemberList
          options={{
            selectionMode: 'multi',
          }}
          selected={memberIds}
          onChange={onChange}
        />
        <div className="search-member-add-member">
          <IonButton onClick={() => onToggle(false)}>
            <IonText>{t('add-member-btn')}</IonText>
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  )
}

OrgMemberModal.propTypes = {
  isOpen: PropTypes.bool,
  ontoggle: PropTypes.func,
  onChange: PropTypes.func,
}

export default OrgMemberModal
