import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IonAvatar,
  IonCheckbox,
  IonGrid,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonText,
} from '@ionic/react'

import { getOrgMembers } from 'redux/org/orgAction'
import { Datatable } from 'components/Datatable'
import { selectOrgMembers } from 'redux/org/orgSlice'

import './OrgMemberList.scss'
import { useTranslation } from 'react-i18next'
import { DateFormatter } from 'components/formatters'

interface IProps {
  selected: string[] | string
  onChange?: any
  onToggle?: any
  options?: {
    selectionMode: string
  }
}

const OrgMemberList = ({ selected, onChange, options, onToggle }: IProps) => {
  const { loading, members, totalPage } = useSelector(selectOrgMembers)
  let _options: any = {
    selectionMode: 'multi',
    ...options,
  }
  const { t } = useTranslation(['table', 'common'])

  const checkedMember = (memberId: string) => {
    return selected.includes(memberId)
  }

  const columns = [
    {
      id: 'select',
      numeric: false,
      disablePadding: true,
      label: '',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('provider-name'),
    },
    {
      id: 'phoneNumber',
      numeric: false,
      disablePadding: false,
      label: t('phonenumber'),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('email'),
    },
    {
      id: 'rating',
      numeric: false,
      disablePadding: false,
      label: t('rating'),
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: t('role'),
    },
    {
      id: 'joinedAt',
      numeric: false,
      disablePadding: false,
      label: t('joined-date'),
    },
  ]

  const transformData = (items: any) => {
    return items.map((item: any) => {
      return {
        select:
          _options.selectionMode === 'multi' ? (
            <IonCheckbox
              value={`${item._id}`}
              checked={checkedMember(item._id)}
              onIonChange={(e: any) =>
                onChange({
                  isChecked: e.target.checked,
                  value: item,
                })
              }
            />
          ) : (
            <IonRadio value={item._id} />
          ),
        name: (
          <>
            <IonAvatar className="team-image-in-list">
              <img
                alt="preview"
                src={
                  item.avatarUrl ||
                  'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                }
              ></img>
            </IonAvatar>
            <IonLabel className="team-item">{item.fullName}</IonLabel>
          </>
        ),
        phoneNumber: (
          <IonLabel className="align-self-center">{item.phoneNumber}</IonLabel>
        ),
        email: <IonLabel className="align-self-center">{item.email}</IonLabel>,

        rating: (
          <IonLabel className="align-self-center">
            {`${t('rating').toLocaleLowerCase()} ${item.rating}/5`}{' '}
          </IonLabel>
        ),
        role: (
          <IonLabel className="align-self-center">{item.role.name}</IonLabel>
        ),
        joinedAt: (
          <IonLabel className="align-self-center">
            <DateFormatter value={item.createdAt} />
          </IonLabel>
        ),
      }
    })
  }

  return (
    <>
      <div className="search-members-div">
        <IonText>{t('title.search-member')}</IonText>
      </div>
      <IonRadioGroup
        {...(_options.selectionMode !== 'multi' && {
          value: selected,
          onIonChange: (e: any) => {
            const selected = members.find(
              (member: any) => member._id === e.target.value
            )
            onChange({ value: selected })
            onToggle(false)
          },
        })}
      >
        <IonGrid className="org-member-list">
          {loading ? (
            <>Loading...</>
          ) : (
            <Datatable
              resource={getOrgMembers}
              columns={columns}
              data={transformData(members)}
              totalPage={totalPage}
              options={{ enabledSelection: false }}
              limit={10}
              query={{ role: 'provider' }}
            />
          )}
        </IonGrid>
      </IonRadioGroup>
    </>
  )
}

OrgMemberList.propTypes = {
  selected: PropTypes.any,
  onChange: PropTypes.func,
  onToggle: PropTypes.func,

}

export default OrgMemberList
