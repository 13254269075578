import React from "react";

import PropTypes from "prop-types";

const ErrorPage = () => {
  return <>ErrorPage</>;
};

ErrorPage.propTypes = {};

export default ErrorPage;
