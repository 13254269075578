import { TDResource } from 'helpers/resource'

const tdResource = new TDResource()
const tdApi = tdResource.getInstance()

export const getNotifications = (query: any) => {
  return tdApi.get('v1/notifications', { params: query })
}

export const readNotification = (notificationId: string) => {
  return tdApi.post('v1/notifications/' + notificationId + '/read')
}
