import { TDResource } from 'helpers/resource'

const tdResource = new TDResource()
const tdApi = tdResource.getInstance()

export const getReports = (query: any) => {
  return tdApi.get(`v1/job_reports`, { params: query })
}

export const getReportById = (reportId: string) => {
  return tdApi.get(`v1/job_reports/${reportId}`)
}

export const update = (reportId: string, data: any) => {
  return tdApi.put(`v1/job_reports/${reportId}`, data)
}

export const approve = (reportId: string) => {
  return tdApi.post(`v1/job_reports/${reportId}/approve`)
}
