import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";

import StateTag from "components/StateTag";
import JobAssignModal from "../JobSubPages/JobAssignModal";
import { getJobById } from "../redux/jobAction";
import { selectJob } from "../redux/jobSlice";

import { JobDetail, JobProvider, JobComment } from "../tabs";

import "./JobInfo.scss";
import { useTranslation } from "react-i18next";

interface params {
  jobId: "";
}

const JobInfo = () => {
  const { jobId } = useParams<params>();
  const dispatch = useDispatch();
  const { loading, job, wfJob } = useSelector(selectJob);
  const [placeholderValue] = useState(null);
  const [assignTeamOrProvider, setAssignTeamOrProvider] = useState<string>("");
  const [reloadPage, setReloadPage] = useState(false);
  const [showAssignJobModal, setShowAssignJobModal] = useState<boolean>(false);
  const { t } = useTranslation(["common"]);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();
  const [tapSelect, setTapSelected] = useState<any>(
    query.get("section") || "details"
  );

  useEffect(() => {
    dispatch(getJobById(jobId));
  }, [reloadPage]);

  const assignJobClicked = (e: any) => {
    if (e.detail.value === "team" || e.detail.value === "provider") {
      setAssignTeamOrProvider(e.detail.value);
    } else if (e.detail.value !== null && assignTeamOrProvider !== "") {
      setAssignTeamOrProvider(assignTeamOrProvider);
    }

    setShowAssignJobModal(true);
  };

  const disabledIonSelect = () => {
    return job.status !== "pending_assign" || job.team;
  };

  const toggleReloadPage = () => setReloadPage(!reloadPage);

  return (
    <IonPage>
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <IonContent>
            <IonGrid class="job-info-header-content">
              <IonRow>
                <IonCol>
                  <IonRouterLink routerLink="/jobs">
                    <div className="teams-info-back-button display-flex">
                      <IonIcon
                        icon={chevronBack}
                        class="align-self-center icon-24"
                      />
                      <IonText class="align-self-center">{t("back")}</IonText>
                    </div>
                  </IonRouterLink>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonRow>
                    <IonLabel class="job-page-label">{job.number}</IonLabel>
                  </IonRow>
                  <IonRow>
                    <IonLabel class="job-info-sub-headers margin-top-12">
                      {wfJob.job.service.name}
                    </IonLabel>
                  </IonRow>
                </IonCol>
                <IonCol>
                  {job.status === "pending_assign" && job.team === null && (
                    <IonItem className="assign-job-button">
                      <IonLabel>{t("assign")}</IonLabel>
                      <IonSelect
                        disabled={disabledIonSelect()}
                        interface="popover"
                        class="assign-job-dropdown"
                        selectedText=" "
                        value={placeholderValue}
                        onIonChange={assignJobClicked}
                      >
                        <IonSelectOption
                          value="team"
                          class="select-option-no-radio"
                        >
                          {t("team")}
                        </IonSelectOption>
                        <IonSelectOption
                          value="provider"
                          class="select-option-no-radio"
                        >
                          {t("provider")}
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel class="job-info-sub-headers">
                    {t("you-will-earn")} {wfJob.job.payout.full_display}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <StateTag
                  value={
                    job.team && job.status === "pending_assign"
                      ? "assign_team"
                      : job.status
                  }
                  round={false}
                />
              </IonRow>
            </IonGrid>
            <IonSegment>
              <IonSegment
                value={tapSelect}
                class="tap-segment"
                onIonChange={(e) => setTapSelected(e.detail.value)}
              >
                <IonSegmentButton value="details">
                  <IonLabel>{t("details")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="providers">
                  <IonLabel>{t("provider")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="comments">
                  <IonLabel>{t("title.comments")}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonSegment>
            {tapSelect === "details" && (
              <JobDetail
                loading={loading}
                job={job}
                wfJob={wfJob}
                setTapSelected={setTapSelected}
                toggleReloadPage={toggleReloadPage}
              />
            )}
            {tapSelect === "providers" && <JobProvider job={job} />}
            {tapSelect === "comments" && (
              <JobComment wfJob={wfJob} toggleReloadPage={toggleReloadPage} />
            )}
          </IonContent>
          {showAssignJobModal ? (
            <JobAssignModal
              jobId={job._id}
              isOpen={showAssignJobModal}
              onCloseCallback={() => setShowAssignJobModal(false)}
              assignType={assignTeamOrProvider}
            />
          ) : null}
        </>
      )}
    </IonPage>
  );
};

JobInfo.propTypes = {};

export default JobInfo;
