import moment from 'moment'

export function RelativeTimeIntevalFormatter({ startTime, endTime }) {
  if (moment(startTime.value).isAfter(endTime.value)) {
    return 'relative_time_interval.invalid'
  }

  // const lowerBound = moment(startTime.value).fromNow()
  // const upperBound = moment(endTime.value).fromNow()

  // const startingIn = t(`relative_time_interval.future_event.${startTime.eventName || 'start'}`, { lowerBound })
  // const startedFrom = t(`relative_time_interval.past_event.${startTime.eventName || 'start'}`, { lowerBound })
  // const endingIn = t(`relative_time_interval.future_event.${endTime.eventName || 'end'}`, { upperBound })
  // const endedFrom = t(`relative_time_interval.past_event.${endTime.eventName || 'end'}`, { upperBound })
  // const conjuction = t('relative_time_interval.conjuction.and')
  // const noEnding = t(`relative_time_interval.no_ending.${endTime.eventName || 'end'}`)

  // if (moment().isAfter(endTime.value)) {
  //   return endedFrom
  // }
  // else if (moment().isBetween(startTime.value, endTime.value)) {
  //   return `${startedFrom} ${conjuction}${endingIn}`
  // }
  // else if (moment().isBefore(startTime.value)) {
  //   return startingIn
  // }
  // else {
  //   return `${startedFrom} ${conjuction}${noEnding}`
  // }
}

export default RelativeTimeIntevalFormatter
