import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IonAvatar, IonLabel } from '@ionic/react'

import { getTeams } from '../../redux/teamAction'
import { selectTeams } from '../../redux/teamSlice'
import { DateFormatter } from 'components/formatters'
import { Datatable } from 'components/Datatable'
import badgeIcon from 'assets/icons/badge.png'

import './TeamPage.scss'
import { useTranslation } from 'react-i18next'

const TeamPage = () => {
  const { t } = useTranslation(['common', 'team', 'table'])
  const { loading, teams, totalPage } = useSelector(selectTeams)

  const columns = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('team'),
    },
    {
      id: 'leader',
      numeric: false,
      disablePadding: false,
      label: t('team-lead'),
    },
    {
      id: 'phoneNumber',
      numeric: false,
      disablePadding: false,
      label: t('contact'),
    },
    {
      id: 'members',
      numeric: false,
      disablePadding: false,
      label: t('number-of-team-members'),
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: t('created-at'),
    },
  ]

  const transformData = (items: any) => {
    return items.map((item: any) => {
      return {
        name: (
          <>
            <IonAvatar className="team-image-in-list">
              <img src={item.pictureUrl} alt="preview"></img>
            </IonAvatar>
            <Link
              className="team-item"
              to={`/teams/teams/${item._id}`}
              key={item._id}
            >
              <IonLabel> {`${item.name}`} </IonLabel>
            </Link>
          </>
        ),
        leader: (
          <>
            <IonLabel className="teams-list-team-lead-elems padding-right-8">
              {`${item.leader?.fullName}`}
            </IonLabel>
            <img
              alt="preview"
              className="teams-list-team-lead-elems "
              src={badgeIcon}
            />
          </>
        ),
        phoneNumber: (
          <IonLabel className="align-self-center">{`${item.leader?.phoneNumber}`}</IonLabel>
        ),
        members: (
          <IonLabel className="align-self-center">
            {`${item.members.length} ${t('member-unit')}`}{' '}
          </IonLabel>
        ),
        createdAt: <DateFormatter value={item.createdAt} />,
      }
    })
  }

  return (
    <div className="tab-teams-list">
      {loading ? (
        <>Loading...</>
      ) : (
        <Datatable
          resource={getTeams}
          columns={columns}
          data={transformData(teams)}
          totalPage={totalPage}
          options={{ enabledSelection: false }}
        />
      )}
    </div>
  )
}

export default TeamPage
