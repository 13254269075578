import { useDispatch, useSelector } from 'react-redux'

import { logout } from 'pages/Login/redux/authAction'

import './UserDropdown.scss'
import { useTranslation } from 'react-i18next'

const UserDropdown = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: any) => state.auth)
  const { t } = useTranslation('common')

  const loggingOut = () => {
    dispatch(logout())
  }

  return (
    <div className="user-box">
      <div className="user-container-box">
        <div className="avatar">
          <img src={user.avatarUrl} alt="avatar" />
        </div>
        <div className="title">{user.fullName}</div>
        <div className="description">{user.email}</div>
        <button className="logout-btn" onClick={loggingOut}>
          {t('logout')}
        </button>
      </div>
    </div>
  )
}

export default UserDropdown
