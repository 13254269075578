import { TDResource } from "helpers/resource";

const tdResource = new TDResource();
const tdApi = tdResource.getInstance();

export const getOrgById = (orgId: string) => {
  return tdApi.get(`v1/organizations/${orgId}`);
};

export const getOrgMembers = (orgId: string, query: any) => {
  return tdApi.get(`v1/organizations/${orgId}/members`, { params: query });
};
