import { useState, createRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react'
import { image, personAddOutline } from 'ionicons/icons'

import { OrgMemberModal } from './OrgMemberModal'
import { TeamLeadModal } from './TeamLeadModal'
import { TeamMemberList } from './TeamMemberList'
import { createTeam, updateTeam, getTeamById } from '../../../redux/teamAction'
import { selectTeamInfo, reset } from '../../../redux/teamSlice'

import './TeamForm.scss'
import { useTranslation } from 'react-i18next'

interface IProps {
  teamId?: string
}

interface Values {
  name: string
  description: string
  leader: any
  picture: any
  previewPicture: string
  members: any
}

const TeamForm = ({ teamId }: IProps) => {
  const { t } = useTranslation(['team', 'common'])
  const dispatch = useDispatch()
  const [isOpenOrgMemberModal, setIsOpenOrgMemberModal] = useState(false)
  const [isOpenTeamLeadModal, setIsOpenTeamLeadModal] = useState(false)
  const teamImageRef: any = createRef()
  const { team } = useSelector(selectTeamInfo)

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamById(teamId))
    }
    dispatch(reset())
  }, [teamId])

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      description: Yup.string(),
      leader: Yup.object().required(),
      members: Yup.array().min(1),
    }),
    enableReinitialize: true,
    initialValues: team,
    onSubmit: (values: Values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      let formData = new FormData()

      formData.append('name', values.name)
      formData.append('description', values.description || '')
      formData.append('leader', values.leader._id)
      formData.append('picture', values.picture)

      const leadMember = values.members.find((member: any) => member.member._id === values.leader._id)
      if (!leadMember) {
        values.members.push({ member: values.leader })
      }

      values.members.forEach(({ member }: any, key: number) => {
        formData.append(`memberIds[${key}]`, member._id)
      })

      if (teamId) {
        dispatch(updateTeam(teamId, formData, { resetForm }))
      } else {
        dispatch(createTeam(formData, { resetForm }))
      }

      setSubmitting(false)
    },
  })

  const onSelectMemberId = (field: string, value: any) => {
    let _value = value

    if (field === 'members') {
      if (value.isChecked) {
        _value = [...formik.values.members, { member: value.value }]
      } else {
        const memberIndex = formik.values.members.findIndex(
          ({ member }: any) => member._id === value.value._id
        )
        _value = [...formik.values.members]
        _value.splice(memberIndex, 1)
      }
    }

    formik.setFieldValue(field, _value)
  }

  const handleChange = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value)
  }

  const filterMembersWithoutTeamLead = (members: any) => members.filter((member: any) => member?.member?._id !== formik.values.leader?._id)

  return (
    <>
      <OrgMemberModal
        memberIds={formik.values.members.map(({ member }: any) => member._id)}
        onToggle={(isOpen: boolean) => setIsOpenOrgMemberModal(isOpen)}
        isOpen={isOpenOrgMemberModal}
        onChange={(e) => onSelectMemberId('members', e)}
      />
      <TeamLeadModal
        leaderId={formik.values.leader?._id}
        onToggle={(isOpen: boolean) => setIsOpenTeamLeadModal(isOpen)}
        isOpen={isOpenTeamLeadModal}
        onChange={(e) => {
          onSelectMemberId('leader', e.value)
          formik.setFieldValue('leaderDisplay', e.display)
        }}
      />
      <form>
        <IonGrid className="grid-create-teams">
          <IonRow className="tab-create-row-1">
            <IonCol className="table-create-row-1-col-1">
              <IonLabel className="tab-create-teams-label">
                {!teamId && t('create-team-by-search')}
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonButton
                color="primary"
                className="add-members-button"
                onClick={() => setIsOpenOrgMemberModal(true)}
              >
                <IonLabel>{t('add-member-btn')}</IonLabel>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="grid-create-teams">
          <IonRow className="create-team-input-rows">
            <IonCol size="9">
              <IonLabel>
                {t('team-lead')} <IonText color="danger">*</IonText>{' '}
                {t('team-lead-description')}
              </IonLabel>
              <IonInput
                className="input"
                placeholder={t('team-lead-placeholder')}
                readonly
                onClick={() => setIsOpenTeamLeadModal(true)}
                value={formik.values.leader?.fullName}
              ></IonInput>
            </IonCol>
            <IonCol>
              <IonButton
                class="add-teams-image-button"
                onClick={() => {
                  teamImageRef?.current?.click()
                }}
              >
                <div
                  onClick={() => {
                    teamImageRef?.current?.click()
                  }}
                >
                  <IonIcon icon={image} hidden={formik.values.picture} />
                  <img
                    alt="preview"
                    className="add-teams-preview-image"
                    src={formik.values.previewPicture}
                    hidden={!formik.values.picture}
                  ></img>
                </div>
              </IonButton>
              <input
                ref={teamImageRef}
                hidden
                type="file"
                accept="image/*"
                onChange={(event: any) => {
                  formik.setFieldValue('picture', event.target.files[0])
                  const reader = new FileReader()
                  reader.onloadend = () => {
                    formik.setFieldValue('previewPicture', reader.result)
                  }
                  reader.readAsDataURL(event.target.files[0])
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="create-team-input-rows">
            <IonCol size="9">
              <IonLabel>
                {t('team-name')} <IonText color="danger">*</IonText>
              </IonLabel>
              <IonInput
                name="name"
                className="input"
                placeholder={t('team-name-placeholder')}
                value={formik.values.name}
                onIonChange={handleChange}
              ></IonInput>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          <IonRow className="create-team-input-rows">
            <IonCol size="9">
              <IonLabel>{t('details')}</IonLabel>
              <IonInput
                name="description"
                className="input"
                placeholder={t('team-details-placeholder')}
                value={formik.values.description}
                onIonChange={handleChange}
              ></IonInput>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel>
                {t('team-member-list')} <IonText color="danger">*</IonText>
              </IonLabel>
              <IonContent className="create-teams-add-members-box">
                {formik.values.members.length === 0 ? (
                  <IonContent className="create-teams-add-members-placeholder">
                    <IonGrid className="create-teams-add-member-placeholder-grid">
                      <IonRow>
                        <IonCol>
                          <IonIcon icon={personAddOutline} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonLabel className="text-align-center">
                            {t('team-member-list-placeholder')}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonText>
                            {t('team-member-list-placeholder-description')}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonButton
                            className="add-members-button"
                            onClick={() => {
                              setIsOpenOrgMemberModal(true)
                            }}
                          >
                            <IonLabel>{t('add-member-btn')}</IonLabel>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonContent>
                ) : (
                  <TeamMemberList members={filterMembersWithoutTeamLead(formik.values.members)} />
                )}
              </IonContent>
            </IonCol>
          </IonRow>
          <IonRow class="right-row">
            <IonButton
              disabled={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              {teamId ? t('save') : t('create-team')}
            </IonButton>
          </IonRow>
        </IonGrid>
      </form>
    </>
  )
}

export default TeamForm
