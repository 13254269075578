import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import { briefcase } from "ionicons/icons";

import DashboardCard from "../../components/DashboardCard";

import { selectDashboard } from "./redux/dashboardSlice";
import { getDashboard } from "./redux/dashboardAction";

import "./Dashboard.scss";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation(["dashboard", "common"]);
  const dispatch = useDispatch();
  const { jobCount } = useSelector(selectDashboard);

  useEffect(() => {
    dispatch(getDashboard());
  }, []);

  return (
    <>
      <IonGrid class="header-content">
        <IonRow id="main-row-1">
          <IonCol>
            <IonLabel id="dashboard-label">Dashboard</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow id="main-row-2">
          <IonCol>
            <IonCard routerLink="/jobs" routerDirection="root">
              <IonCardHeader>
                <IonCardTitle>
                  {t("incomming_jobs")}
                  <IonIcon icon={briefcase} />
                  <span className="badge badge-assertive">
                    {jobCount.incoming}
                  </span>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>{t("incomming_jobs_description")}</IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid id="secondary-content">
        <IonRow id="secondary-row-1">
          <IonCol>
            <IonLabel class="secondary-labels"> {t("job")} </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow id="secondary-row-2">
          <IonCol>
            <DashboardCard
              description={t("all_jobs")}
              number={jobCount.all}
              unit={t("job_unit")}
              color="blue-light"
            />
          </IonCol>
          <IonCol>
            <DashboardCard
              description={t("pending_assign")}
              number={jobCount.pendingAssign}
              unit={t("job_unit")}
              color="blue-gray"
            />
          </IonCol>

          <IonCol>
            <DashboardCard
              description={t("completed_jobs")}
              number={jobCount.completed}
              unit={t("job_unit")}
              color="green"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Dashboard;
