import { configureStore } from '@reduxjs/toolkit'

import authReducer from 'pages/Login/redux/authSlice'
import dashboardReducer from 'pages/Dashboard/redux/dashboardSlice'
import teamReducer from 'pages/Team/redux/teamSlice'
import orgReducer from './org/orgSlice'
import alertReducer from './alert/alertSlice'
import jobReducer from 'pages/Job/redux/jobSlice'
import reportReducer from 'pages/Report/redux/reportSlice'
import notificationReducer from './notification/notificationSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    team: teamReducer,
    org: orgReducer,
    job: jobReducer,
    alert: alertReducer,
    report: reportReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
