import { IonAvatar, IonCol, IonRow, IonLabel } from '@ionic/react'
import { DateFormatter } from 'components/formatters'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

interface IProps {
  members: any
}

export const TeamMemberList = ({ members }: IProps) => {
  const { t } = useTranslation(['common', 'table'])
  return (
    <>
      {members.map(({ member }: any) => (
        <IonRow key={member._id}>
          <IonCol>
            <IonAvatar className="team-image-in-list">
              <img
                alt="preview"
                src={
                  member.avatarUrl ||
                  'https://seekster-company.oss-ap-southeast-1.aliyuncs.com/workforce-images/default_avatar.png'
                }
              ></img>
            </IonAvatar>
            <IonLabel className="team-item">{member.fullName}</IonLabel>
          </IonCol>
          <IonCol>{member.role?.name}</IonCol>
          <IonCol>{member.phoneNumber}</IonCol>
          <IonCol>{`${t('rating')} ${member.rating}/5`}</IonCol>
          <IonCol>
            {`${t('joined-since')}`} <DateFormatter value={member.createdAt} />
          </IonCol>
        </IonRow>
      ))}
    </>
  )
}

TeamMemberList.propTypes = {
  members: PropTypes.array,
}
