import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useDropzone } from 'react-dropzone'
import { useFormik } from 'formik'
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRouterLink,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from '@ionic/react'
import { camera, chevronBack, closeCircle, image } from 'ionicons/icons'
import { isEmpty, sortBy } from 'lodash'
import { serialize } from 'utils/serialize'

import { getReportById, approve as approveReport } from '../redux/reportAction'
import { selectReport } from '../redux/reportSlice'
import { getJobById, updateJobProvider } from 'pages/Job/redux/jobAction'
import { selectJob } from 'pages/Job/redux/jobSlice'

import './ReportEditPage.scss'
import { useTranslation } from 'react-i18next'

interface Params {
  reportId: string
}

const ReportEditPage: React.FC = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  // const history = useHistory()
  const { reportId } = useParams<Params>()
  const { report } = useSelector(selectReport)
  const { loading: jobLoading, wfJob } = useSelector(selectJob)
  const reportQuestions = wfJob?.reportQuestions || []
  const answers = wfJob?.reportAnswers || []
  const jobProvider = wfJob?.jobProvider || {}

  const refs = useMemo(
    () => reportQuestions.map(() => React.createRef()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportQuestions.length, answers.length]
  )

  const [step] = useState<string>('1')

  useEffect(() => {
    dispatch(getReportById(reportId))
  }, [])

  useEffect(() => {
    if (!isEmpty(report)) {
      dispatch(getJobById(report.job._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report])

  const initailValues = {
    answers_attributes: reportQuestions.map((question: any) => {
      const questionId = question.id
      const ans = answers.find((a: any) => a.question.id === questionId)

      return {
        ...ans,
        id: ans?.id,
        question_id: questionId,
        question: question,
      }
    }),
  }

  const parseAnswers = async (values: any) => {
    const answer_attributes = await Promise.all(
      values.answers_attributes.map(async (answer: any) => {
        const attachments_attributes = await Promise.all(
          answer?.attachments?.map(async (attFile: any) => {
            if (attFile.id) return attFile

            return {
              file: attFile,
            }
          })
        )

        interface Data {
          [key: string]: any
        }

        const data: Data = {
          id: answer.id,
          question_id: answer?.question?.id || answer?.question_id,
        }

        if (answer.value) data['value'] = answer.value
        if (answer['choice'] || answer['choice_id'])
          data['choice_id'] = answer?.choice?.id || answer?.choice_id
        if (answer['choices'] && answer['choices'].length > 0)
          data['choice_ids'] = answer?.choices?.map((ch: any) => ch.id)
        else data['choice_ids'] = []

        if (
          answer?.attachments &&
          answer?.attachments.length > 0 &&
          attachments_attributes.length > 0
        )
          data['attachments_attributes'] = attachments_attributes
        else data['attachments_attributes'] = []

        if (answer.image) {
          data['image'] = answer.image
        }

        return data
      })
    )

    return [answer_attributes]
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initailValues,
    onSubmit: async (values: any) => {
      const [answersAttributes] = await parseAnswers(values)

      console.log(
        '🚀 ~ file: ReportEditPage.tsx ~ line 198 ~ onSubmit: ~ answersAttributes',
        answersAttributes
      )

      const data: any = {
        providerId: parseInt(jobProvider?.provider?.id),
        answers_attributes: answersAttributes,
      }

      const seralizedData = serialize(data, {
        // indices: true,
        allowEmptyArrays: true,
      })

      dispatch(updateJobProvider(jobProvider.id, seralizedData)).then(() => {
        dispatch(getJobById(report.job._id))
        dispatch(approveReport(report._id))
      })
    },
  })

  const formikStep2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      attachments: [],
    },
    onSubmit: (values) => {
      console.log('values: ', values)
    },
  })

  const onDrop = useCallback(
    (acceptedFiles) => {
      formikStep2.setFieldValue('attachments', [
        ...formikStep2.values.attachments,
        ...acceptedFiles,
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formikStep2.values]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSubmitStep1 = () => {
    formik.handleSubmit()
  }

  const handleUploadImage = (index: number) => {
    refs[index].current?.click()
  }

  const renderAnswer = (answer: any, index: number) => {
    const fieldName = `answers_attributes[${index}]`

    switch (answer?.question?.display_type) {
      case 'string':
      case 'textarea':
        return (
          <IonTextarea
            name={`${fieldName}.value`}
            value={answer?.value}
            rows={4}
            onIonChange={formik.handleChange}
          />
        )
      case 'number':
        return (
          <IonInput
            name={`${fieldName}.value`}
            onIonChange={formik.handleChange}
            type="number"
            value={answer?.value}
          />
        )
      case 'radio':
        return (
          <IonRadioGroup
            name={fieldName}
            value={answer?.choice?.id}
            onIonChange={(e) => {
              const selectedChoice = e.detail.value
              formik.setFieldValue(
                `${fieldName}.choice`,
                answer?.question?.choices?.find(
                  (c: any) => c.id === selectedChoice
                )
              )
            }}
          >
            {answer?.question?.choices?.map((choice: any) => (
              <IonItem key={choice.id} lines="none">
                <IonRadio
                  className="ion-margin-end"
                  slot="start"
                  value={choice?.id}
                />
                <IonLabel>{choice?.value}</IonLabel>
              </IonItem>
            ))}
          </IonRadioGroup>
        )
      case 'checkboxes':
        return (
          <IonList lines="none">
            {answer?.question?.choices?.map((choice: any) => (
              <IonItem key={choice?.id}>
                <IonCheckbox
                  name={fieldName}
                  slot="start"
                  checked={
                    answer?.choices.findIndex((ch: any) => {
                      return ch.id === choice.id
                    }) > -1
                  }
                  value={choice?.id}
                  onIonChange={(e) => {
                    const { value: choiceId } = e.detail
                    const choiceIndex = answer?.choices.findIndex(
                      (choice: any) => choice.id === parseInt(choiceId)
                    )

                    if (choiceIndex === -1) {
                      const newChoices = [...answer?.choices, choice]
                      const sortedChoices = sortBy(
                        newChoices,
                        (c) => c.position
                      )
                      formik.setFieldValue(
                        `${fieldName}.choices`,
                        sortedChoices
                      )
                    } else if (choiceIndex > -1) {
                      const newChoices = [...answer?.choices]
                      newChoices.splice(choiceIndex, 1)
                      formik.setFieldValue(`${fieldName}.choices`, newChoices)
                    }
                  }}
                />
                <IonLabel>{choice?.value}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )
      case 'select':
      case 'searchable_select':
        return (
          <IonSelect
            name={`${fieldName}.choice`}
            // value={answer?.choice}
            compareWith={(o1, o2) => (o1 && o2 ? o1.id === o2.id : o1 === o2)}
            interface="popover"
            onIonChange={(choice) => {
              formik.setFieldValue(`${fieldName}.choice`, choice.detail.value)
            }}
          >
            {answer?.question?.choices?.map((choice: any) => (
              <IonSelectOption value={choice}>{choice.value}</IonSelectOption>
            ))}
          </IonSelect>
        )
      case 'camera':
      case 'gallery':
        return (
          <div className="image-input-wrapper">
            <IonGrid className="attachments-grid">
              <IonRow>
                <IonCol size="3" style={{ minHeight: '200px' }}>
                  {answer.image && (
                    <IonIcon
                      className="close-icon"
                      icon={closeCircle}
                      onClick={() => {
                        formik.setFieldValue(`${fieldName}.image`, null)
                      }}
                    />
                  )}
                  <div
                    className="image-input"
                    onClick={() => handleUploadImage(index)}
                  >
                    {answer.image ? (
                      <IonImg
                        src={
                          answer?.image?.file_url
                            ? answer.image.file_url
                            : URL.createObjectURL(answer.image)
                        }
                      />
                    ) : (
                      <IonIcon icon={camera} />
                    )}
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      name={fieldName}
                      onChange={(e) => {
                        const files = e.target.files
                        if (files) {
                          const file = files[0]
                          if (file) {
                            formik.setFieldValue(`${fieldName}.image`, file)
                          }
                        }
                      }}
                      ref={refs[index]}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )
      case 'camera_multiple':
      case 'gallery_multiple':
        return (
          <div className="image-input-wrapper">
            <IonGrid className="attachments-grid">
              <IonRow>
                {answer?.attachments?.map((attachment: any, index: number) => {
                  if (attachment._destroy) return null
                  if (attachment.file_url)
                    return (
                      <IonCol key={attachment?.id} size="3">
                        <div className="image-input">
                          <IonImg src={attachment.file_url} />
                          <IonIcon
                            className="close-icon"
                            icon={closeCircle}
                            onClick={() => {
                              formik.setFieldValue(
                                `${fieldName}.attachments[${index}]._destroy`,
                                true
                              )
                            }}
                          />
                        </div>
                      </IonCol>
                    )
                  else {
                    return (
                      <IonCol key={attachment?.id} size="3">
                        <div className="image-input">
                          <IonImg src={URL.createObjectURL(attachment)} />
                          <IonIcon
                            className="close-icon"
                            icon={closeCircle}
                            onClick={() => {
                              const temp = [...answer.attachments]
                              temp.splice(index, 1)
                              formik.setFieldValue(
                                `${fieldName}.attachments`,
                                temp
                              )
                            }}
                          />
                        </div>
                      </IonCol>
                    )
                  }
                })}
                <IonCol size="3" style={{ minHeight: '200px' }}>
                  <div
                    className="image-input"
                    onClick={() => handleUploadImage(index)}
                  >
                    <IonIcon icon={camera} />
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      name={fieldName}
                      onChange={(e) => {
                        const files = e.target.files
                        if (files) {
                          const file = files[0]
                          const previousAttachments =
                            formik?.values?.answers_attributes[index]
                              ?.attachments || []
                          if (file) {
                            formik.setFieldValue(`${fieldName}.attachments`, [
                              ...previousAttachments,
                              file,
                            ])
                          }
                        }
                        e.target.value = ''
                      }}
                      ref={refs[index]}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )
      default:
        return <div></div>
    }
  }

  const renderAnswerList = () => {
    return (
      <IonList className="answer-list" lines="none">
        {formik.values.answers_attributes.map((answer: any, i: number) => (
          <IonItem
            key={answer?.id}
            className={`answer-item ion-margin-bottom ${answer?.question?.display_type}`}
          >
            <div slot="start">{`${i + 1}/${
              formik.values.answers_attributes.length
            }`}</div>
            <div>
              <IonText>{answer?.question?.name}</IonText>
              <div>{renderAnswer(answer, i)}</div>
            </div>
          </IonItem>
        ))}
      </IonList>
    )
  }

  if (jobLoading) return <>Loading...</>

  return (
    <IonPage className="report-edit-page">
      <IonContent scrollY={false}>
        <IonGrid className="report-edit-nav-grid">
          <IonRow className="back-row ion-align-items-center">
            <IonCol>
              <IonRouterLink routerLink={`/reports/${reportId}`}>
                <IonItem lines="none" color="no">
                  <IonIcon
                    className="ion-margin-end"
                    slot="start"
                    icon={chevronBack}
                  />
                  <IonText>{t('btn.back')}</IonText>
                </IonItem>
              </IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className="job-page-content report-edit-header-grid">
          <IonRow>
            <IonCol className="">
              <IonLabel className="job-page-label report-edit-page-label ">
                <IonText>{t('title.edit-report')}</IonText>
                {/* // TODO : dynamic show page */}
                {/* <p className="ion-padding-start">ขั้นตอน {step} จาก 2</p> */}
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel class="job-info-sub-headers color-dark-blue font-weight-bold">
                {step === '1'
                  ? t('title.additional-completed-job-question')
                  : 'อัพโหลดรูปภาพหลังการทำงาน'}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
        {step === '1' && (
          <IonGrid className="job-page-content report-edit-page-content step-1">
            <IonRow>
              <IonCol>{renderAnswerList()}</IonCol>
            </IonRow>
            <IonRow className="save-button-row">
              <IonCol size="12" className="ion-align-items-end">
                <IonButton onClick={handleSubmitStep1}>{t('save')}</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {step === '2' && (
          <IonGrid className="job-page-content report-edit-page-content step-2">
            <IonRow>
              <IonCol>
                <div className="drag-drop-zone" {...getRootProps()}>
                  <IonIcon icon={image} />
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag & drop or <u>browse</u> your images
                    </p>
                  )}
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-margin-vertical">รูปภาพ</IonCol>
            </IonRow>
            <IonRow className="report-attachment-row">
              {formikStep2.values.attachments.map(
                (attachmentFile: any, i: number) => (
                  <IonCol key={attachmentFile?.id} size="3">
                    <IonImg src={URL.createObjectURL(attachmentFile)} />
                  </IonCol>
                )
              )}
            </IonRow>

            <IonRow className="save-button-row ion-margin-verticle">
              <IonCol size="12">
                <IonButton>บันทึก</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  )
}

export default ReportEditPage
