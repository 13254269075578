import { Dispatch } from "redux";

import { setJobCountByState, setError } from "./dashboardSlice";
import { getDashboard as getDashboardApi } from "./dashboardApi";

export const getDashboard = (): any => async (dispatch: Dispatch) => {
  try {
    const { data } = await getDashboardApi();
    const { jobCount } = data;

    dispatch(setJobCountByState(jobCount));
  } catch (error) {
    dispatch(setError(error));
  }
};
