import { Dispatch } from 'redux'

import {
  getReportsSuccess,
  getReportSuccess,
  approveReportSuccess,
  setLoading,
  setError,
} from './reportSlice'
import { show as showAlert } from 'redux/alert/alertSlice'
import i18n from 'i18n'

import * as reportApi from './reportApi'

export const getReports =
  (query: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      const { data: reports } = await reportApi.getReports(query)

      dispatch(getReportsSuccess(reports))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const getReportById =
  (reportId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      const { data: report } = await reportApi.getReportById(reportId)

      dispatch(getReportSuccess(report))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const approve =
  (reportId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      const { data: reportApproved } = await reportApi.approve(reportId)

      dispatch(approveReportSuccess(reportApproved))
      dispatch(setLoading(false))
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.approve-job-success'),
          redirect: `/reports/${reportId}`,
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const updateReport =
  (reportId: string, data: any): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))

      await reportApi.update(reportId, data)

      dispatch(setLoading(false))
      dispatch(
        showAlert({
          type: 'success',
          message: i18n.t('common:message.edit-report-success'),
        })
      )
    } catch (error) {
      dispatch(setError(error))
    }
  }
