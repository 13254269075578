import { useState } from 'react'
import { IonContent, IonPage, IonSplitPane } from '@ionic/react'
import PropTypes from 'prop-types'

import GlobalToolbar from 'components/GlobalToolbar'
import GlobalMenu from 'components/GlobalMenu'

import './Layout.scss'

interface IProps {
  children: any
}

const Layout = ({ children }: IProps) => {
  const [isSplitPaneDisable, setIsSplitPaneDisable] = useState<boolean>(false)

  const handleMenuOpen = () => {
    setIsSplitPaneDisable(!isSplitPaneDisable)
  }

  return (
    <IonPage>
      <GlobalToolbar handleMenuOpen={handleMenuOpen} />
      <IonContent>
        <IonSplitPane
          className="layout-split-pane"
          contentId="main"
          when="(min-width: 992px)"
          disabled={isSplitPaneDisable}
        >
          <GlobalMenu />
          <IonPage id="main">
            <IonContent>{children}</IonContent>
          </IonPage>
        </IonSplitPane>
      </IonContent>
    </IonPage>
  )
}

Layout.propTypes = {
  children: PropTypes.element,
}

export default Layout
